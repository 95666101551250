import React, { useEffect, useState } from "react";
import mqtt from "mqtt";
import { Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { UpdateIdentifier, UpdateUser } from "app-redux/actions";

import "antd/dist/antd.css";
import "./Login.css";
import { PublicHeader, PublicFooter } from "../components";
import QRCode from "qrcode.react";
import forge from "node-forge";
import { ApplicationState } from "schema/app";
import { UserApi } from "api";

const decryptWithPrivateKey = (message: string, encryptKey: string) => {
	const iv = forge.util.hexToBytes(message.substr(0, 32));
	const cipher = forge.cipher.createDecipher("AES-CBC", encryptKey);
	cipher.start({ iv });
	cipher.update(
		forge.util.createBuffer(forge.util.hexToBytes(message.substr(32)))
	);
	cipher.finish();

	const decrypted = cipher.output;
	return decrypted.data;
};

const makeid = (length: number) => {
	var result = "";
	var characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

const publicKey =
	"-----BEGIN PUBLIC KEY-----\
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCrhgEb0XuVBWJ1I5GmJm3Pe+YU\
ta5/Qou5W6KDK22QgbeRkWiUiOvSSOvBg/rvIMnAhjWSsXjwIC/7fx82np2M8uwe\
zYUASNHg0IAcXTu4HTdmyI6gdGOcNzmaa84oZMISv7phY453fekgcL82HC8kv0iR\
LOR9bJfCNuttYhB6VQIDAQAB\
-----END PUBLIC KEY-----";

export default function CliLogin() {
	const history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector((state: ApplicationState) => state.user);
	const identifier = useSelector((state: ApplicationState) => state.identifier);

	const [loading, setLoading] = useState(false);
	const [cliToken, setCliToken] = useState<string>(user?.cli_access_token);
	const [encryptKey] = useState(makeid(32));
	const [topic] = useState(makeid(10));
	const qr_code_data = `acheckin://login?pk=${encryptKey}&tp=${topic}&app_id=io.acheckin.connect`;
	const mqtt_topic = `/login/${topic}/token`;

	const handleMessage = (topicFromMessage: string, payload: Buffer) => {
		if (topicFromMessage === mqtt_topic) {
			setLoading(true);

			const data = payload.toString();
			const token = decryptWithPrivateKey(data, encryptKey);

			dispatch(
				UpdateIdentifier({
					access_token: token,
				})
			);

			setTimeout(() => {
				UserApi.current()
					.then((u) => {
						dispatch(UpdateUser(u));
						setLoading(false);
						setCliToken(u.cli_access_token);
					})
					.catch((err) => {
						message.error(err.message);
						setLoading(false);
					});
			}, 1000);
		}
	};

	useEffect(() => {
		if (identifier?.access_token) {
		} else {
			const mqttClient = mqtt.connect("wss://mqtt01.acheckin.vn:8883", {
				username: "acheckin",
				password: "acheckin",
			});

			mqttClient.on("connect", () => {
				mqttClient.subscribe("/login/" + topic + "/#");
			});

			mqttClient.on("message", handleMessage);
			mqttClient.on("error", (err) => {});

			// clean up
			return () => {
				mqttClient.end();
			};
		}
	});

	return (
		<div>
			<PublicHeader />
			<div className="login-section">
				{cliToken ? (
					<p>
						Copy access token below and use in CLI
						<br />
						{cliToken}
					</p>
				) : (
					<>
						<p>Scan QR code from ACheckin to sign in</p>
						<br />
						<Spin tip="Signing in..." spinning={loading}>
							<QRCode value={qr_code_data} />
						</Spin>
					</>
				)}
			</div>
			<PublicFooter />
		</div>
	);
}
