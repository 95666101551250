import React, { useState, useEffect } from "react";
import moment from "moment";
import { List, Avatar, message, Layout, Typography } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { InboxApi } from "api";
import { InboxMessage, ApplicationState } from "schema/app";

type Props = {
	onClickItem: (item: InboxMessage) => void;
};

export default function InboxList({ onClickItem }: Props) {
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const [messages, setMessages] = useState<Array<InboxMessage>>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		InboxApi.list()
			.then((inboxMes) => {
				setLoading(false);
				setMessages(inboxMes);
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	}, [identifier.access_token]);

	const handleClickItem = (ib: InboxMessage) => {
		if (ib.read == false)
			InboxApi.read(ib.id).then((inbox) => {
				const inboxIndex = messages.findIndex((i) => i.id == inbox.id);
				if (inboxIndex > -1) {
					const newMess = [...messages];
					newMess[inboxIndex].read = true;
					setMessages(newMess);
				}
			});
		onClickItem(ib);
	};
	return (
		<List
			itemLayout="horizontal"
			loading={loading}
			dataSource={messages}
			style={{ overflow: "auto", maxHeight: "calc(100vh - 174px)" }}
			renderItem={(item) => (
				<List.Item
					style={{
						cursor: "pointer",
						backgroundColor: item.read ? "#FFF" : "#F1F1F1",
						padding: 6,
						overflow: "hidden",
					}}
					onClick={() => handleClickItem(item)}
				>
					<List.Item.Meta
						avatar={<Avatar>{item.team?.name?.charAt(0) || "A"}</Avatar>}
						title={
							<span>
								{item.team ? `[${item.team?.name}] ` : ""}
								{item.subject} {}{" "}
								{item.invoice && item.invoice.pdf ? <PaperClipOutlined /> : ""}
							</span>
						}
						description={<span>{moment(item.created_at).fromNow()}</span>}
					/>
				</List.Item>
			)}
		/>
	);
}
