import { SupportTicket, TicketComment } from "schema/app";
import request from "./request";

export const SUPPORT_URL = "/v1/support_tickets";

export const list = (
	status: string,
	ticket_type: string,
	page: number,
	limit: number
) =>
	request<Array<SupportTicket>>("GET", SUPPORT_URL, {
		status,
		ticket_type,
		page,
		limit,
	});

export const get = (ticketId: string) =>
	request<SupportTicket>("GET", `${SUPPORT_URL}/${ticketId}`);

export const create = ({
	ticket_type,
	subject,
	content,
	application_id = null,
	invoice_id = null,
}) => {
	let parameters = {};
	switch (ticket_type) {
		case "general": {
			parameters = {
				ticket_type,
				subject,
				content,
			};
			break;
		}
		case "application": {
			parameters = {
				ticket_type,
				subject,
				content,
				application_id,
			};
			break;
		}
		case "invoice": {
			parameters = {
				ticket_type,
				subject,
				content,
				invoice_id,
			};
			break;
		}
		default:
			break;
	}

	return request<SupportTicket>("POST", SUPPORT_URL, parameters);
};

export const update = ({ id, status }) => {
	return request<SupportTicket>("PUT", `${SUPPORT_URL}/${id}`, {
		status,
	});
};

export const reply = ({ id, content }) => {
	return request<TicketComment>("POST", `${SUPPORT_URL}/${id}/comments`, {
		content,
	});
};
