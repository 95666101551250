import React, { useEffect, useState } from "react";
import moment from "moment";
import {
	Modal,
	message,
	Space,
	Avatar,
	Descriptions,
	Typography,
	Table,
	Button,
	List,
} from "antd";
import { useParams } from "react-router-dom";
import {
	UserOutlined,
	MessageOutlined,
	LikeOutlined,
	StarOutlined,
} from "@ant-design/icons";

import { HOST } from "config/api";
import { ApplicationApi } from "api";
import {
	ApplicationState,
	User,
	Application,
	Version,
	Team,
	LiteUser,
	Review,
} from "schema/app";

const IconText = ({ icon, text }) => (
	<Space>
		{React.createElement(icon)}
		{text}
	</Space>
);

const ViewMessagesModal = ({
	visible,
	app,
	appVersion,
	appMessages,
	onOk,
	onCancel,
}) => {
	const [application, setApplication] = useState(app);
	const [version, setVersion] = useState(appVersion);
	const [messages, setMessages] = useState(appMessages);

	useEffect(() => {
		setApplication(app);
		setVersion(appVersion);
		setMessages(appMessages);
	}, [application, appVersion, appMessages]);

	return (
		<Modal
			title={
				<Space>
					<Avatar src={`${HOST}${application.logo}`} />
					<Typography.Text>{application.name}</Typography.Text>
					<Typography.Text>{`- Lịch sử tin nhắn trao đổi - phiên bản ${version}`}</Typography.Text>
				</Space>
			}
			width={1000}
			centered
			visible={visible}
			destroyOnClose
			okText="OK"
			cancelButtonProps={{ style: { display: "none" } }}
			onOk={onOk}
			onCancel={onCancel}
		>
			<div style={{ height: 600, overflow: "auto" }}>
				<List
					itemLayout="vertical"
					size="default"
					dataSource={messages}
					renderItem={(item: Review) => (
						<List.Item key={item.id}>
							<List.Item.Meta
								avatar={<Avatar src={item.reviewer.picture} />}
								title={item.reviewer.name}
							/>
							{item.content}
						</List.Item>
					)}
				/>
			</div>
		</Modal>
	);
};

export default ViewMessagesModal;
