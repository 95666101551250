import React, { useState } from "react";
import { Menu, Layout, Divider } from "antd";
import {
	HomeOutlined,
	AppstoreOutlined,
	TeamOutlined,
	QuestionCircleOutlined,
	WalletOutlined,
	FileProtectOutlined,
} from "@ant-design/icons";

import { useHistory, useRouteMatch } from "react-router";
import { useSelector } from "react-redux";
import { ApplicationState } from "schema/app";

export default function Sidebar() {
	const history = useHistory();
	const match = useRouteMatch();

	let selectedKey = history.location.pathname
		.replace("/admin", "")
		.replace("/", "");
	if (selectedKey == "") {
		selectedKey = "admin";
	}

	if (selectedKey.indexOf("apps") != -1) {
		selectedKey = "apps";
	}

	const [collapsed, setCollapsed] = useState(false);
	const onCollapse = (collapsed) => {
		setCollapsed(collapsed);
	};

	return (
		<Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
			<Menu
				selectedKeys={[selectedKey]}
				onSelect={(params) => {
					const { key } = params;
					if (key == "dashboard") {
						history.push(match.url);
						return;
					}
					const urlToGo = `${match.url}/${key}`;
					history.push(urlToGo);
				}}
			>
				{/* <Menu.Item key="admin">
					<HomeOutlined />
					<span>Tổng quát</span>
				</Menu.Item> */}
				<Menu.Item key="people">
					<TeamOutlined />
					<span>Thành viên</span>
				</Menu.Item>
				<Menu.Item key="apps">
					<AppstoreOutlined />
					<span>Quản lý ứng dụng</span>
				</Menu.Item>
				<Menu.Item key="review">
					<FileProtectOutlined />
					<span>Xét duyệt ứng dụng</span>
				</Menu.Item>
				<Menu.Item key="support">
					<QuestionCircleOutlined />
					<span>Danh sách Yêu cầu</span>
				</Menu.Item>
			</Menu>
		</Layout.Sider>
	);
}
