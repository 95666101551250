import React, { useEffect, useState } from "react";
import moment from "moment";
import {
	Modal,
	Typography,
	Table,
	Button,
	Tag,
	Space,
	Form,
	Radio,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useHistory } from "react-router";

import { SupportTicket, TICKET_STATUS, TICKET_TYPE } from "schema/app";
import { SupportApi } from "api";

const columns = (onView, onEdit) => [
	{
		title: "Thời gian",
		dataIndex: "created_at",
		key: "created_at",
		render: (value: number, row: any, index: number) => {
			return moment(value).format("DD/MM/YYYY HH:mm:ss");
		},
	},
	{
		title: "Loại",
		dataIndex: "ticket_type",
		key: "ticket_type",
		render: (text, record) => {
			return (
				<Tag color={TICKET_TYPE[text].color} key={`${text}`}>
					{TICKET_TYPE[text].label}
				</Tag>
			);
		},
	},
	{
		title: "Trạng thái",
		dataIndex: "status",
		key: "status",
		render: (text, record) => {
			return (
				<Tag color={TICKET_STATUS[text].color} key={`${text}`}>
					{TICKET_STATUS[text].label}
				</Tag>
			);
		},
	},
	{
		title: "Tiêu Đề",
		dataIndex: "subject",
		key: "subject",
	},
	{
		key: "action",
		render: (value: boolean, record: any, index: number) => {
			return (
				<Space size={10}>
					<Button
						type="primary"
						onClick={() => {
							onView(record, index);
						}}
					>
						Xem
					</Button>
					<Button
						type="primary"
						onClick={() => {
							onEdit(record, index);
						}}
						danger={record.status === "open"}
					>
						{record.status === "open" ? "Đóng" : "Mở"}
					</Button>
				</Space>
			);
		},
	},
];

const Supports = () => {
	const history = useHistory();
	const [tickets, setTickets] = useState([]);
	const [ticketType, setTicketType] = useState(null);
	const [status, setStatus] = useState(null);

	useEffect(() => {
		loadTickets(status, ticketType);
	}, []);

	const loadTickets = (status, type) => {
		SupportApi.list(status, type, 1, 100).then((items: SupportTicket[]) => {
			setTickets(items);
		});
	};

	const onStatusChange = (value) => {
		setStatus(value.target.value);
		loadTickets(value.target.value, ticketType);
	};

	const onTicketTypeChange = (value) => {
		setTicketType(value.target.value);
		loadTickets(status, value.target.value);
	};

	const onView = (ticket: SupportTicket, index: number) => {
		history.push(`/admin/support/${ticket.id}`);
	};

	const onEdit = (ticket: SupportTicket, index: number) => {
		Modal.confirm({
			title: `Bạn có chắc muốn ${
				ticket.status === "open" ? "đóng" : "mở lại"
			} yêu cầu này không?`,
			icon: <ExclamationCircleOutlined />,
			okText: "Có",
			okType: "danger",
			cancelText: "Không",
			onOk() {
				SupportApi.update({
					id: ticket.id,
					status: ticket.status === "open" ? "closed" : "open",
				}).then((ticket: SupportTicket) => {
					const index = tickets.findIndex((item: SupportTicket) => {
						return item.id === ticket.id;
					});
					if (index !== -1) {
						let newTickets = tickets;
						newTickets[index] = ticket;
						setTickets(newTickets.slice());
					}
				});
			},
			onCancel() {},
		});
	};

	return (
		<div>
			<Typography.Title level={4}>Danh sách yêu cầu</Typography.Title>
			<Form layout="inline">
				<Form.Item label="Trạng thái">
					<Radio.Group value={status} onChange={onStatusChange}>
						<Radio.Button value={null}>Tất cả</Radio.Button>
						{/* <Radio.Button value="open">Mở</Radio.Button>
						<Radio.Button value="closed">Đóng</Radio.Button> */}
					</Radio.Group>
				</Form.Item>
				<Form.Item label="Loại">
					<Radio.Group value={ticketType} onChange={onTicketTypeChange}>
						<Radio.Button value={null}>Tất cả</Radio.Button>
						<Radio.Button value="general">Tổng quát</Radio.Button>
						<Radio.Button value="application">Ứng dụng</Radio.Button>
						<Radio.Button value="invoice">Hóa đơn</Radio.Button>
					</Radio.Group>
				</Form.Item>
			</Form>
			<Table columns={columns(onView, onEdit)} dataSource={tickets} />
		</div>
	);
};
export default Supports;
