import axios from "axios";

import { HOST } from "config/api";
import { User, ErrorDescription, Team, Invoice } from "schema/app";

import { store } from "app-redux";
import request from "./request";

const LOGIN_URL = "/v1/users/sign_in";
const SIGN_UP_URL = "/v1/users/sign_up";
const CURRENT_URL = "/v1/users/current";
const SEARCH_URL = "/v1/users";
const TEAM_URL = "/v1/teams";
const WITHDRAW_URL = "/v1/users/withdraw";

export const acheckin_sign_up = (
	email: string,
	phone_number: string,
	name: string,
	username: string,
	access_token: string,
	workspace_id: string,
	staff_id: string
) => {
	return axios
		.post(HOST + SIGN_UP_URL, {
			email,
			phone_number,
			name,
			username,
			access_token,
			workspace_id,
			staff_id,
		})
		.then((response) => {
			if (response && response.data) {
				const { id } = response.data;
				if (id) {
					return response.data as User;
				}
				const { message, errors } = response.data;
				const error: ErrorDescription = {
					message,
					errors,
					name: "",
				};
				throw error;
			}
			throw new Error("Sign up failed");
		})
		.catch((err) => {
			let response = err.response;

			const { message, errors } = response.data;
			const error: ErrorDescription = {
				message,
				errors,
				name: "",
			};
			throw error;
		});
};

export const search = (email: string) =>
	request<Array<User>>("GET", SEARCH_URL, { email });

export const current = () => request<User>("GET", CURRENT_URL);

export const withdraw = (amount: number) =>
	request<Invoice>("POST", WITHDRAW_URL, {
		amount,
	});
