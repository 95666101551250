import React, { useEffect, useState } from "react";
import { DatePicker, Switch, Modal, InputNumber, Form, message } from "antd";
import { useParams } from "react-router-dom";

import { ApplicationApi } from "api";
import { AppPrice } from "schema/app";

const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};

const CreatePricingModal = ({ visible, onOk, onCancel }) => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const { id } = useParams();

	return (
		<Modal
			title="Thêm thiết lập giá"
			centered
			visible={visible}
			destroyOnClose
			confirmLoading={loading}
			onOk={() => {
				setLoading(true);
				form
					.validateFields()
					.then((values) => {
						ApplicationApi.createAppPrice(
							id,
							values.price,
							values.start_at && values.start_at.unix(),
							values.end_at && values.end_at.unix(),
							values.available
						)
							.then((responseItem: AppPrice) => {
								if (responseItem.id) {
									onOk(responseItem);
									form.resetFields();
								} else {
									message.error("Lỗi! Không thể tạo giá cho ứng dụng này");
								}
								setLoading(false);
							})
							.catch(() => {
								setLoading(false);
							});
					})
					.catch((info) => {
						console.log("Validate Failed:", info);
						setLoading(false);
					});
			}}
			onCancel={onCancel}
		>
			<Form
				form={form}
				initialValues={{
					price: 0,
					available: true,
					start_at: null,
					end_at: null,
				}}
			>
				<Form.Item {...formItemLayout} name={"price"} label="Giá">
					<InputNumber
						step={100}
						formatter={(value) =>
							`đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => (value as string).replace(/\đ\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						contentEditable="false"
						min={0}
					/>
				</Form.Item>
				<Form.Item {...formItemLayout} name={"start_at"} label="Bắt đầu">
					<DatePicker
						showTime
						style={{ width: "100%" }}
						placeholder="Không giới hạn"
					/>
				</Form.Item>
				<Form.Item {...formItemLayout} name={"end_at"} label="Kết thúc">
					<DatePicker
						showTime
						style={{ width: "100%" }}
						placeholder="Không giới hạn"
					/>
				</Form.Item>
				<Form.Item
					{...formItemLayout}
					name={"available"}
					label="Kích hoạt"
					valuePropName="checked"
				>
					<Switch />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default CreatePricingModal;
