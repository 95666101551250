import React, { useState, useEffect } from "react";
import { Menu, Avatar, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";

import { ApplicationState, Application } from "schema/app";

import { ApplicationApi } from "api";

import "./AppsMenu.css";
import { HOST } from "config/api";

const NEW_APPLICATION_PATH = "/dashboard/apps/new";

export default function AppsMenu() {
	const history = useHistory();
	const match = useRouteMatch();
	const identifier = useSelector((state: ApplicationState) => state.identifier);

	const { id } = useParams();

	const [apps, setApps] = useState<Array<Application>>([]);
	const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (identifier?.team_id == null) return;
		ApplicationApi.list(1, 100, identifier?.team_id)
			.then((applications) => {
				setApps(applications);

				const currentApp = id
					? applications.find((a) => a.id == id)
					: undefined;

				if (id != "new" && currentApp == undefined) {
					history.replace(match.path.replace("/:id", ""));
				}
				setSelectedKey(id);
			})
			.catch((err) => {
				message.error(err.message);
			});
	}, [identifier]);

	const handleChangeApplication = (value: string) => {
		setSelectedKey(value);
		if (value == "new") {
			history.push(NEW_APPLICATION_PATH);
		} else {
			history.push(
				match.path.replace("/:id", "") + "/" + value + history.location.search
			);
		}
	};

	return (
		<div>
			<Menu
				selectedKeys={[selectedKey]}
				onSelect={({ key }) => {
					handleChangeApplication(key);
				}}
			>
				<Menu.Item key="new">
					<Avatar icon={<PlusOutlined />} /> <span>Tạo mới</span>
				</Menu.Item>
				{apps.map((application) => {
					return (
						<Menu.Item key={application.id}>
							{application.logo ? (
								<Avatar src={HOST + application.logo} />
							) : (
								<Avatar>{application.name.charAt(0).toUpperCase()}</Avatar>
							)}{" "}
							<span>{application.name}</span>
						</Menu.Item>
					);
				})}
			</Menu>
		</div>
	);
}
