import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from "antd";

import { ApplicationState } from "schema/app";
import Application from "modules/application";
import { TeamManagement } from "uikit";

import { Sidebar, Header } from "./components";
import {
	AllTeams,
	CreateTeam,
	Dashboard,
	Analytics,
	TeamInfo,
	Payments,
	Supports,
	ViewSupportTicket,
} from "./pages";

import "./index.scss";

export default function Developer() {
	const match = useRouteMatch();
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const team = useSelector((state: ApplicationState) => state.team);

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Header />
			<Switch>
				<Route path={`${match.path}/teams/new`} component={CreateTeam} />
				<Route>
					{identifier.team_id && identifier.team_id != "administration" ? (
						team && team.role.name != "Tester" ? (
							<Layout>
								<Sidebar />
								<Layout.Content>
									<Switch>
										<Route path={`${match.path}/people`}>
											<TeamManagement type="team" />
										</Route>
										<Route
											path={`${match.path}/analytics`}
											component={Analytics}
										/>
										<Route path={`${match.path}/info`} component={TeamInfo} />
										<Route
											path={`${match.path}/payment`}
											component={Payments}
										/>
										<Route
											path={`${match.path}/apps/:id`}
											component={Application}
										/>
										<Route
											path={`${match.path}/apps`}
											component={Application}
										/>
										<Route
											path={`${match.path}/support/:id`}
											component={ViewSupportTicket}
										/>
										<Route
											path={`${match.path}/support`}
											component={Supports}
										/>
										{/* <Route component={Dashboard} /> */}
									</Switch>
								</Layout.Content>
							</Layout>
						) : null
					) : (
						<AllTeams />
					)}
				</Route>
			</Switch>
		</Layout>
	);
}
