import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { TeamApi } from "api";
import { BaseHeader } from "uikit";
import { ApplicationState, Team, TeamByWorkspace } from "schema/app";
import { useHistory } from "react-router";
import { UpdateTeam, UpdateIdentifier, UpdateTeams } from "app-redux/actions";

const NEW_TEAM_PATH = "/dashboard/teams/new";

const teamsByWorkspace = (teams: Array<Team>): Array<TeamByWorkspace> => {
	// group team by workspace
	let teamByWorkspaces: { [key: string]: Array<Team> } = {};
	teams.forEach((team) => {
		// for (let i = 0; i < teams.length; i++) {
		// let team = teams[i];
		let groupName = "Personal";
		if (team.workspace_id) {
			groupName = "Workspace";
		}
		if (teamByWorkspaces[groupName] == undefined)
			teamByWorkspaces[groupName] = [];
		teamByWorkspaces[groupName].push(team);
		// }
	});

	let optGroups: Array<TeamByWorkspace> = [];
	for (let group in teamByWorkspaces) {
		let teams = teamByWorkspaces[group];
		optGroups.push({
			name: group,
			teams: teams,
		});
	}
	return optGroups;
};

export default function Header() {
	const user = useSelector((state: ApplicationState) => state.user);
	const teams = useSelector((state: ApplicationState) => state.teams);
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const history = useHistory();
	const dispatch = useDispatch();

	const handleChangeTeam = (value: string) => {
		if (teams === undefined) return;
		if (value == "new" || value == undefined) {
			dispatch(UpdateTeam(undefined));
			dispatch(
				UpdateIdentifier({
					...identifier,
					team_id: undefined,
				})
			);
			history.push(NEW_TEAM_PATH);
		} else {
			const nextTeam = teams.find((t) => t.id == value);
			if (nextTeam == undefined) return;
			dispatch(UpdateTeam(nextTeam));
			dispatch(
				UpdateIdentifier({
					...identifier,
					team_id: nextTeam.id,
				})
			);
		}
	};

	useEffect(() => {
		TeamApi.list().then((list) => {
			dispatch(UpdateTeams(list));
			dispatch(
				UpdateTeam(
					identifier?.team_id
						? list.find((t) => t.id == identifier?.team_id)
						: null
				)
			);
		});
	}, [user]);

	return (
		<BaseHeader>
			<Select
				showSearch
				className="ml-2"
				style={{ width: 200 }}
				placeholder="Vui lòng chọn team"
				optionFilterProp="children"
				onChange={handleChangeTeam}
				defaultValue={
					identifier?.team_id == "administration"
						? undefined
						: identifier?.team_id
				}
			>
				<Select.Option value="new">+ Tạo Team</Select.Option>

				{teamsByWorkspace(teams).map((teamByWorkspace) => {
					return (
						<Select.OptGroup
							label={teamByWorkspace.name}
							key={teamByWorkspace.name}
						>
							{teamByWorkspace.teams.length > 0
								? teamByWorkspace.teams.map((team) => {
										return (
											<Select.Option
												key={team.id}
												value={team.id}
												disabled={team ? team.role?.name == "Tester" : true}
											>
												{team.name}
											</Select.Option>
										);
								  })
								: null}
						</Select.OptGroup>
					);
				})}
			</Select>
		</BaseHeader>
	);
}
