import { Team } from "schema/app";
import { TEAM_UPDATE } from "../actions/types";
import { TeamUpdate } from "../actions";

const initialState: Team | null = null;

export default function (state = initialState, action: TeamUpdate) {
	switch (action.type) {
		case TEAM_UPDATE:
			return action.team ? ({ ...action.team } as Team) : null;
		default:
			return state;
	}
}
