import React from "react";
import { Breadcrumb } from "antd";
import { UserOutlined } from "@ant-design/icons";

type Props = {
	title: string;
};

export default function Header({ title }: Props) {
	return (
		<Breadcrumb>
			<Breadcrumb.Item href="/personal">
				<UserOutlined />
				<span>Cá nhân</span>
			</Breadcrumb.Item>
			<Breadcrumb.Item>{title}</Breadcrumb.Item>
		</Breadcrumb>
	);
}
