import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import { ApplicationState } from "schema/app";

/**
 * Redux
 */
const mapStateToProps = (state: ApplicationState) => ({
	identifier: state.identifier,
});

const connector = connect(mapStateToProps, null, null, {
	forwardRef: true,
});

/**
 * Props
 */

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux &
	RouteProps & {
		isPrivate?: boolean;
		isAuth?: boolean;
	};

const RouteWrapper: React.FunctionComponent<Props> = ({
	component,
	isPrivate,
	isAuth,
	identifier,
	...rest
}: Props) => {
	const signed =
		identifier != null &&
		identifier.access_token != null &&
		identifier.access_token != "";
	/**
	 * Redirect user to SignIn page if he tries to access a private route
	 * without authentication.
	 */
	if (isPrivate == true && !signed) {
		return <Redirect to="/login" />;
	}
	/**
	 * Redirect user to Main page if he tries to access a non private route
	 * (SignIn or SignUp) after being authenticated.
	 */
	if (isAuth && signed) {
		return <Redirect to="/dashboard" />;
	}

	/**
	 * If not included on both previous cases, redirect user to the desired route.
	 */
	return <Route component={component} {...rest} />;
};

RouteWrapper.defaultProps = {
	isPrivate: false,
	isAuth: false,
};

export default connector(RouteWrapper);
