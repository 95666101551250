import React, { useState } from "react";
import { Form, Input, Select, Button, Row, Col, message } from "antd";
import { VersionApi } from "api";
import { useSelector } from "react-redux";
import { ApplicationState, Version } from "schema/app";
import { useParams } from "react-router";

const layout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
};
const tailLayout = {
	wrapperCol: { offset: 6, span: 18 },
};

type Props = {
	onVersionCreated: (v: Version) => void;
};

export default function CreateVersion({ onVersionCreated }: Props) {
	const { id } = useParams();
	const identifier = useSelector((state: ApplicationState) => state.identifier);

	const [loading, setLoading] = useState(false);

	const onFinish = (values) => {
		setLoading(true);
		const { name, description } = values;
		VersionApi.createOrUpdate(
			{ name, description: description || "" },
			id,
			identifier.team_id
		)
			.then((v) => {
				setLoading(false);
				onVersionCreated(v);
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<Form {...layout} onFinish={onFinish} onFinishFailed={onFinishFailed}>
			<Row>
				<Col span={18} offset={6}>
					<h5>Tạo phiên bản mới</h5>
				</Col>
			</Row>
			<Form.Item
				label="Tên phiên bản"
				name="name"
				rules={[
					{
						required: true,
						message: "Vui lòng nhập tên ứng dụng tối thiểu 3 kí tự!",
						min: 3,
					},
				]}
			>
				<Input />
			</Form.Item>
			<Form.Item label="Mô tả" name="description">
				<Input.TextArea />
			</Form.Item>
			<Form.Item {...tailLayout}>
				<Button type="primary" htmlType="submit" loading={loading}>
					Tạo phiên bản
				</Button>
			</Form.Item>
		</Form>
	);
}
