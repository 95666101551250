import React, { useEffect, useState } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import {
	Row,
	Col,
	Typography,
	Statistic,
	Progress,
	Table,
	Button,
	Tag,
	Space,
	Form,
	Radio,
} from "antd";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { HOST } from "config/api";

import {
	ApplicationState,
	SupportTicket,
	TICKET_STATUS,
	TICKET_TYPE,
} from "schema/app";
import { SupportApi } from "api";

import { CreateSupportTicketModal } from "../components";

const columns = (onView) => [
	{
		title: "Thời gian",
		dataIndex: "created_at",
		key: "created_at",
		render: (value: number, row: any, index: number) => {
			return moment(value).format("DD/MM/YYYY HH:mm:ss");
		},
	},
	{
		title: "Loại",
		dataIndex: "ticket_type",
		key: "ticket_type",
		render: (text, record) => {
			return (
				<Tag color={TICKET_TYPE[text].color} key={`${text}`}>
					{TICKET_TYPE[text].label}
				</Tag>
			);
		},
	},
	{
		title: "Trạng thái",
		dataIndex: "status",
		key: "status",
		render: (text, record) => {
			return (
				<Tag color={TICKET_STATUS[text].color} key={`${text}`}>
					{TICKET_STATUS[text].label}
				</Tag>
			);
		},
	},
	{
		title: "Tiêu Đề",
		dataIndex: "subject",
		key: "subject",
	},
	{
		key: "action",
		render: (value: boolean, record: any, index: number) => {
			return (
				<Space size={10}>
					<Button
						type="primary"
						onClick={() => {
							onView(record, index);
						}}
					>
						Xem
					</Button>
				</Space>
			);
		},
	},
];

const Supports = () => {
	const history = useHistory();
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [ticketType, setTicketType] = useState(null);
	const [status, setStatus] = useState(null);

	const identifier = useSelector((state: ApplicationState) => state.identifier);

	useEffect(() => {
		loadTickets(status, ticketType);
	}, [identifier]);

	const onView = (ticket: SupportTicket, index: number) => {
		history.push(`/dashboard/support/${ticket.id}`);
	};

	const loadTickets = (status, type) => {
		SupportApi.list(status, type, 1, 100).then((items: SupportTicket[]) => {
			setTickets(items);
		});
	};

	const onStatusChange = (value) => {
		setStatus(value.target.value);
		loadTickets(value.target.value, ticketType);
	};

	const onTicketTypeChange = (value) => {
		setTicketType(value.target.value);
		loadTickets(status, value.target.value);
	};

	return (
		<div>
			<Row>
				<Col>
					<Typography.Title level={4}>Hỗ trợ</Typography.Title>
				</Col>
				<Col style={{ marginLeft: "auto" }}>
					<Button
						type="primary"
						onClick={() => {
							setShowCreateModal(true);
						}}
					>
						Tạo ticket
					</Button>
				</Col>
			</Row>
			<Form layout="inline">
				<Form.Item label="Trạng thái">
					<Radio.Group value={status} onChange={onStatusChange}>
						<Radio.Button value={null}>Tất cả</Radio.Button>
						<Radio.Button value="open">Mở</Radio.Button>
						<Radio.Button value="closed">Đóng</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item label="Loại">
					<Radio.Group value={ticketType} onChange={onTicketTypeChange}>
						<Radio.Button value={null}>Tất cả</Radio.Button>
						<Radio.Button value="general">Tổng quát</Radio.Button>
						<Radio.Button value="application">Ứng dụng</Radio.Button>
						<Radio.Button value="invoice">Hóa đơn</Radio.Button>
					</Radio.Group>
				</Form.Item>
			</Form>
			<Table columns={columns(onView)} dataSource={tickets} />
			<CreateSupportTicketModal
				visible={showCreateModal}
				onOk={(item: SupportTicket) => {
					setTickets([item, ...tickets]);
					setShowCreateModal(false);
				}}
				onCancel={() => {
					setShowCreateModal(false);
				}}
			/>
		</div>
	);
};
export default Supports;
