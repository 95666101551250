import React, { useState } from "react";
import {
	Row,
	Col,
	Table,
	Typography,
	Descriptions,
	Button,
	message,
	Space,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { ApplicationState } from "schema/app";
import { EditTeamInfoModal } from "../components";

const TeamInfo = ({}) => {
	const [showEditModal, setShowEditModal] = useState(false);
	const team = useSelector((state: ApplicationState) => state.team);

	return (
		<Row style={{ backgroundColor: "#FFF", padding: 10 }}>
			<Col span={10}>
				{/* <Typography.Title level={3}>Thông tin team</Typography.Title>
				<Typography.Text>Tên team</Typography.Text> */}
				<Descriptions title="Thông tin team" bordered>
					<Descriptions.Item label="Tên" span={3}>
						<Row gutter={10} style={{ alignItems: "center" }}>
							<Col>
								<Typography.Text>{team.name}</Typography.Text>
							</Col>
							<Col>
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setShowEditModal(true);
									}}
								/>
							</Col>
						</Row>
					</Descriptions.Item>
					{team.workspace_id ? (
						<Descriptions.Item label="Workspace ID" span={3}>
							{team.workspace_id}
						</Descriptions.Item>
					) : null}
				</Descriptions>
			</Col>
			<EditTeamInfoModal
				item={team}
				visible={showEditModal}
				onOk={() => {
					setShowEditModal(false);
				}}
				onCancel={() => setShowEditModal(false)}
			/>
		</Row>
	);
};

export default TeamInfo;
