import React from "react";

import "./SimpleInfo.css";
import { Typography, Tooltip, Space, Row, Col } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

type Props = {
	title: string;
	showTip?: boolean;
	tip?: string;
	value?: string;
	children?: React.ReactNode;
};

export default function SimpleInfo({
	title,
	value,
	showTip,
	tip,
	children,
}: Props) {
	return (
		<div className="simple-info">
			<Row gutter={5}>
				<Col>
					<h6>{title}</h6>
				</Col>
				{showTip ? (
					<Col>
						<Tooltip title={tip} className="tip">
							<InfoCircleTwoTone twoToneColor={"#0fa44a"} />
						</Tooltip>
					</Col>
				) : null}
			</Row>
			{value ? <Typography.Text strong>{value}</Typography.Text> : null}
			{children ? children : null}
		</div>
	);
}
