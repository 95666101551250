import React, { useState } from "react";
import { Button, Select, Input, Form, Modal, message } from "antd";
import { connect, ConnectedProps, useSelector, useDispatch } from "react-redux";
import { withRouter, RouteChildrenProps, useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import "./CreateTeam.css";
import {
	User,
	ApplicationState,
	Team,
	Workspace,
	Identifier,
} from "schema/app";
import {
	UpdateUser,
	UpdateIdentifier,
	UpdateTeam,
	UpdateTeams,
} from "app-redux/actions";

import { TeamApi, WorkspaceApi } from "api";

const layout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 16 },
};
const tailLayout = {
	wrapperCol: { offset: 4, span: 16 },
};

export default function CreateTeam() {
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const teams = useSelector((state: ApplicationState) => state.teams);
	const dispatch = useDispatch();
	const history = useHistory();

	const [formLoading, setFormLoading] = useState(false);

	const onFinish = (values) => {
		const name = values["name"];

		setFormLoading(true);

		TeamApi.create(name)
			.then((team) => {
				setFormLoading(false);

				identifier.team_id = team.id;
				dispatch(UpdateIdentifier(identifier));
				dispatch(UpdateTeam(team));
				dispatch(UpdateTeams([...teams, team]));

				history.push("/dashboard");
			})
			.catch((err) => {
				message.error(`Lỗi: ${err.message}`);
				setFormLoading(false);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className="create-team">
			<h4>Tạo Team mới</h4>
			<Form
				{...layout}
				name="create-team"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Form.Item
					label="Tên team"
					name="name"
					rules={[
						{ required: true, message: "Vui lòng nhập tên Team của bạn!" },
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item {...tailLayout}>
					<Button type="primary" htmlType="submit" loading={formLoading}>
						Tạo mới
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
}
