import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Typography, Button, Empty, Spin, message, Divider } from "antd";
import { FileTextOutlined } from "@ant-design/icons";

import { TeamApi } from "api";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState, Team } from "schema/app";
import { SimpleInfo } from "uikit";

import PaymentProfileModal from "./PaymentProfileModal";
import { UpdateTeam } from "app-redux/actions";

export default function PaymentInfo({}) {
	const dispatch = useDispatch();
	const [modalVisible, setModalVisible] = useState(false);
	const team = useSelector((state: ApplicationState) => state.team);

	return (
		<div>
			<Typography.Title level={4}>Thông tin thanh toán</Typography.Title>
			{team && team.payment_info?.profile ? (
				<div>
					<SimpleInfo
						title="Tên Công ty hoặc Cá nhân"
						value={team.payment_info?.profile?.name ?? ""}
					/>
					<SimpleInfo
						title="Địa chỉ"
						value={team.payment_info?.profile?.address ?? ""}
					/>
					<SimpleInfo
						title="Mã số thuế"
						value={team.payment_info?.profile?.tax_number ?? ""}
					/>
					<Divider />
					<Typography.Title level={4}>Thông tin tài khoản</Typography.Title>
					<SimpleInfo
						title="Tên tài khoản"
						value={team.payment_info?.account?.name ?? ""}
					/>
					<SimpleInfo
						title="Số tài khoản"
						value={team.payment_info?.account?.number ?? ""}
					/>
					<SimpleInfo
						title="Ngân hàng"
						value={team.payment_info?.account?.bank_name ?? ""}
					/>
					<SimpleInfo
						title="Địa chỉ Ngân hàng"
						value={team.payment_info?.account?.bank_address ?? ""}
					/>
					<Button type="primary" onClick={() => setModalVisible(true)}>
						Đổi thông tin
					</Button>
				</div>
			) : (
				<Empty description="Bạn chưa tạo thông tin thanh toán">
					<Button type="primary" onClick={() => setModalVisible(true)}>
						Tạo thông tin thanh toán
					</Button>
				</Empty>
			)}
			<PaymentProfileModal
				visible={modalVisible}
				paymentProfile={team?.payment_info}
				onTeamUpdated={(t) => {
					setModalVisible(false);
					dispatch(UpdateTeam(t));
				}}
				onCancel={() => {
					setModalVisible(false);
				}}
			/>
		</div>
	);
}
