import React, { useEffect, useState } from "react";
import {
	Input,
	Typography,
	Comment,
	Form,
	Avatar,
	Tooltip,
	List,
	message,
	Row,
	Col,
	Button,
	Space,
	Tag,
} from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";

import { SupportApi } from "api";
import {
	SupportTicket,
	TicketComment,
	TICKET_TYPE,
	TICKET_STATUS,
	ApplicationState,
} from "schema/app";
import "./ViewSupportTicket.css";

const formItemLayout = {
	labelCol: { span: 2 },
	wrapperCol: { span: 6 },
};

const ViewSupportTicket = ({}) => {
	const { id } = useParams();

	const [subject, setSubject] = useState("");
	const [status, setStatus] = useState("");
	const [ticketType, setTicketType] = useState("");
	const [content, setContent] = useState("");
	const [comments, setComments] = useState([]);
	const [invoiceId, setInvoiceId] = useState("");
	const [applicationBundle, setApplicationBundle] = useState("");

	useEffect(() => {
		SupportApi.get(id).then((item) => {
			setSubject(item.subject);
			setStatus(item.status);
			setTicketType(item.ticket_type);
			setComments([
				{
					type: "content",
					user: {
						name: "Nội dung",
					},
					content: item.content,
				},
				...item.comments,
			]);
			setInvoiceId(item.invoice ? item.invoice.id : "");
			setApplicationBundle(item.application ? item.application.bundle_id : "");
		});
	}, [id]);

	return (
		<div>
			<Row gutter={10}>
				<Col span={24}>
					<Row>
						<Col span={24} className="payments-section">
							<Space align="center">
								<Tag
									color={status !== "" ? TICKET_STATUS[status].color : ""}
									key={`${status}`}
								>
									{status !== "" ? TICKET_STATUS[status].label : ""}
								</Tag>
								<Tag
									color={ticketType !== "" ? TICKET_TYPE[ticketType].color : ""}
									key={`${ticketType}`}
								>
									{ticketType !== "" ? TICKET_TYPE[ticketType].label : ""}
								</Tag>
								{ticketType === "application" ? (
									<Tag color={"blue"}>{applicationBundle}</Tag>
								) : null}
								{ticketType === "invoice" ? (
									<Tag color={"blue"}>{`#${invoiceId}`}</Tag>
								) : null}
							</Space>
							<Row style={{ marginTop: 10 }}>
								<Typography.Text style={{ fontSize: 20, fontWeight: "bolder" }}>
									{`Tiêu Đề: ${subject}`}
								</Typography.Text>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col span={24} className="payments-section">
							<List
								className="message-list"
								style={{ marginLeft: 20 }}
								dataSource={comments}
								renderItem={(item) => {
									if (item.type === "content") {
										return (
											<List.Item key={item.id}>
												<List.Item.Meta
													title={item.user.name}
													description={item.content}
												/>
											</List.Item>
										);
									}
									return (
										<List.Item key={item.id}>
											<List.Item.Meta
												avatar={<Avatar />}
												title={item.user.name}
												description={item.content}
											/>
										</List.Item>
									);
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={24} className="payments-section">
							<Row>
								<Col span="22">
									<Input
										value={content}
										disabled={status === "closed"}
										placeholder={"Nhập nội dung"}
										onChange={(target) => {
											setContent(target.currentTarget.value);
										}}
									/>
								</Col>
								<Col span="1" style={{ marginLeft: 10 }}>
									<Button
										type="primary"
										disabled={status === "closed"}
										onClick={() => {
											if (content !== "") {
												SupportApi.reply({
													id,
													content,
												}).then((item: TicketComment) => {
													setComments([...comments, item]);
													message.info("Đã gửi tin nhắn thành công!");
												});
											} else {
												message.error("Lỗi! Vui lòng nhập nội dung!");
											}
										}}
									>
										Gửi
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default ViewSupportTicket;
