import React, { useState } from "react";
import { Form, Select, Spin } from "antd";

import { User } from "schema/app";
import { UserApi } from "api";

type Props = {
	onSelectUser: (user: User) => void;
};

export default function SearchUser({ onSelectUser }: Props) {
	let autoCompleteTimer: number = undefined;
	const [listUsers, setListUsers] = useState<Array<User>>([]);

	const [form] = Form.useForm();

	const searchUser = (value: string) => {
		if (autoCompleteTimer) clearTimeout(autoCompleteTimer);
		autoCompleteTimer = window.setTimeout(() => {
			UserApi.search(value)
				.then((users) => {
					setListUsers(users);
				})
				.catch((err) => {
					console.log(err.message);
				});
		}, 500);
	};

	return (
		<Form
			form={form}
			onValuesChange={(values) => {
				if ("user" in values) {
					const u = listUsers.find((u) => u.id == values["user"]["value"]);
					if (u) onSelectUser(u);
				}
				if (form) form.resetFields();
			}}
		>
			<Form.Item name="user">
				<Select
					placeholder="Tìm kiếm người dùng"
					showSearch
					labelInValue
					onSearch={searchUser}
					style={{ width: "100%" }}
					filterOption={false}
				>
					{listUsers?.map((member) => (
						<Select.Option key={member.id} value={member.id}>
							{member.name}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
		</Form>
	);
}
