import axios, { AxiosResponse } from "axios";

import { HOST } from "config/api";
import { Invitation, ErrorDescription } from "schema/app";

import { store } from "app-redux";
import { InvitationApi } from "api";
import request from "./request";

const INVITATION_URL = "/v1/invitations";

export const list = (
	page: number = 1,
	limit: number = 20,
	role?: string,
	teamId?: string
) =>
	request<Array<Invitation>>("GET", INVITATION_URL, {
		page,
		limit,
		role,
	});

export const createOrUpdate = (invitation: Invitation, teamId?: string) =>
	invitation.id == undefined
		? request<Invitation>("POST", INVITATION_URL, {
				user_id: invitation.user.id,
				role_id: invitation.role.id,
		  })
		: request<Invitation>("PUT", INVITATION_URL + "/" + invitation.id, {
				role_id: invitation.role.id,
		  });

export const remove = (invitation: Invitation, teamId?: string) =>
	request<Invitation>("DELETE", INVITATION_URL + "/" + invitation.id);
