import React, { Component, FormEvent, ChangeEvent, useState } from "react";
import { Button, Select, Input, Col, Form, Row, Layout, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { ApplicationState } from "schema/app";
import { UpdateIdentifier } from "app-redux/actions";

import { ApplicationApi } from "api";

import "./NewApplication.css";

const layout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
};
const tailLayout = {
	wrapperCol: { offset: 6, span: 18 },
};

export default function NewApplication() {
	const history = useHistory();
	const match = useRouteMatch();
	const dispatch = useDispatch();
	const identifier = useSelector((state: ApplicationState) => state.identifier);

	const [loading, setLoading] = useState(false);

	const onFinish = (values) => {
		console.log("Success:", values);
		const { name, bundleId, appType } = values;
		setLoading(true);
		ApplicationApi.createOrUpdate(
			{
				name,
				bundle_id: bundleId,
				application_type: appType,
			},
			identifier.team_id
		)
			.then((application) => {
				setLoading(false);
				history.push(`${match.path.replace("/:id", "")}/${application.id}`);
				dispatch(UpdateIdentifier({ ...identifier }));
			})
			.catch((err) => {
				message.error(err.message);
				setLoading(false);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<Layout.Content>
			<Row>
				<Col span={6} />
				<Col span={18}>
					<h4>Tạo ứng dụng mới</h4>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Form {...layout} onFinish={onFinish} onFinishFailed={onFinishFailed}>
						<Form.Item
							label="Tên"
							name="name"
							rules={[
								{
									required: true,
									message: "Vui lòng nhập tên ứng dụng tối thiểu 5 kí tự!",
									min: 5,
								},
							]}
						>
							<Input></Input>
						</Form.Item>
						<Form.Item
							label="Bundle ID"
							name="bundleId"
							rules={[
								{
									required: true,
									message: "Vui lòng nhập bundle ID tối thiểu 8 kí tự!",
									min: 8,
								},
							]}
						>
							<Input></Input>
						</Form.Item>
						<Form.Item
							label="Loại ứng dụng"
							name="appType"
							rules={[
								{ required: true, message: "Vui lòng chọn loại ứng dụng!" },
							]}
						>
							<Select>
								<Select.Option value="PERSONAL">
									Public for Personal
								</Select.Option>
								<Select.Option value="WORKSPACE">
									Public for Workspace
								</Select.Option>
								<Select.Option value="WORKSPACE_ENTERPRISE">
									Private Workspace only
								</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="submit" loading={loading}>
								Tạo ứng dụng
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Layout.Content>
	);
}
