import { Invoice } from "schema/app";
import { INVOICES_UPDATE, INVOICE_ADD } from "../actions/types";
import { InvoiceAdd, InvoicesUpdate } from "../actions";

const initialState: Invoice[] = [];

export default function (
	state = initialState,
	action: InvoiceAdd | InvoicesUpdate
) {
	switch (action.type) {
		case INVOICES_UPDATE:
			return action.invoices ? action.invoices.slice() : [];
		case INVOICE_ADD:
			return [...state, action.invoice];
		default:
			return state;
	}
}
