import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Layout } from "antd";

import { ApplicationState } from "schema/app";
import Application from "modules/application";
import { BaseHeader, TeamManagement } from "uikit";

import { Sidebar } from "./components";
import {
	Dashboard,
	AppSearch,
	AppReview,
	Supports,
	ViewSupportTicket,
} from "./pages";
import { UpdateIdentifier } from "app-redux/actions";

export default function Admin() {
	const match = useRouteMatch();
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const team = useSelector((state: ApplicationState) => state.team);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(UpdateIdentifier({ ...identifier, team_id: "administration" }));
	}, [match.path]);

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<BaseHeader />
			<Layout>
				<Sidebar />
				<Layout.Content>
					<Switch>
						<Route path={`${match.path}/people`}>
							<TeamManagement type="administration" />
						</Route>
						<Route path={`${match.path}/apps`} component={AppSearch} />
						<Route path={`${match.path}/review`} component={AppReview} />
						<Route
							path={`${match.path}/support/:id`}
							component={ViewSupportTicket}
						/>
						<Route path={`${match.path}/support`} component={Supports} />
						{/* <Route component={Dashboard} /> */}
					</Switch>
				</Layout.Content>
			</Layout>
		</Layout>
	);
}
