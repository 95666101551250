import React from "react";
import { Layout } from "antd";
import { Switch, Route, useRouteMatch } from "react-router";

import { BaseHeader } from "uikit";

import { Inbox, Info } from "./pages";

export default function Personal() {
	const match = useRouteMatch();

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<BaseHeader />
			<Switch>
				<Route path={`${match.path}/inbox`} component={Inbox} />
				<Route component={Info} />
			</Switch>
		</Layout>
	);
}
