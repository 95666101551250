import React from "react";
import { Layout, Tabs, Menu, Row, Col, Empty } from "antd";

import { useParams, useLocation, useHistory } from "react-router";
import {
	NewApplication,
	AppInfo,
	AppPricing,
	ReleaseManagement,
} from "./pages";

import "./index.css";
import { AppsMenu } from "./components";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function Application() {
	const { id } = useParams();
	const history = useHistory();
	const query = useQuery();
	const activeTab = query.get("tab");

	return (
		<Layout.Content>
			<Row gutter={10}>
				<Col span={6}>
					<AppsMenu />
				</Col>
				<Col span={18} className="apps-content">
					{id == undefined ? (
						<Empty description={<span>Vui lòng chọn ứng dụng</span>}></Empty>
					) : id == "new" ? (
						<NewApplication />
					) : (
						<Tabs
							defaultActiveKey={activeTab}
							animated={false}
							onChange={(tab) => {
								history.push(`${history.location.pathname}?tab=${tab}`);
							}}
						>
							<Tabs.TabPane tab="Thông tin" key="info">
								<AppInfo />
							</Tabs.TabPane>
							<Tabs.TabPane tab="Giá" key="pricing">
								<AppPricing />
							</Tabs.TabPane>
							<Tabs.TabPane tab="Phiên bản" key="version">
								<ReleaseManagement />
							</Tabs.TabPane>
						</Tabs>
					)}
				</Col>
			</Row>
		</Layout.Content>
	);
}
