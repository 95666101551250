import { Team } from "schema/app";
import { TEAMS_UPDATE } from "../actions/types";
import { TeamsUpdate } from "../actions";

const initialState: Array<Team> = [];

export default function (state = initialState, action: TeamsUpdate) {
	switch (action.type) {
		case TEAMS_UPDATE:
			return action.teams ? (action.teams.slice() as Array<Team>) : [];
		default:
			return state;
	}
}
