import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { PlusOutlined } from "@ant-design/icons";

import { UpdateTeam, UpdateIdentifier } from "app-redux/actions";
import { ApplicationState } from "schema/app";

import "./AllTeams.scss";
import { TeamItem } from "../components";
import { Divider } from "antd";

export default function AllTeams() {
	const teams = useSelector((state: ApplicationState) => state.teams);
	const identifider = useSelector(
		(state: ApplicationState) => state.identifier
	);
	const history = useHistory();
	const match = useRouteMatch();
	const dispatch = useDispatch();

	return (
		<div className="team-selection">
			<div>
				<div>
					<ul className="allteams">
						<TeamItem
							key="new"
							name="Tạo Team mới"
							icon={<PlusOutlined />}
							onClick={() => {
								history.push(`${match.path}/teams/new`);
							}}
						/>
					</ul>
				</div>
				<Divider />- hoặc - <h3>Chọn team của bạn</h3>
				<Divider />
				<ul className="allteams">
					{teams.map((team) => (
						<TeamItem
							key={team.id}
							name={team.name}
							onClick={() => {
								if (team.role.name == "Tester") return;
								dispatch(
									UpdateIdentifier({ ...identifider, team_id: team.id })
								);
								dispatch(UpdateTeam(team));
							}}
						/>
					))}
				</ul>
			</div>
		</div>
	);
}
