import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Spin, Empty, message } from "antd";

import { VersionApi } from "api";
import { Version, ApplicationState } from "schema/app";

import VersionInfo from "./VersionInfo";

export default function ReleasedSection() {
	const { id } = useParams();

	const identifier = useSelector((state: ApplicationState) => state.identifier);

	const [loading, setLoading] = useState(true);
	const [version, setVersion] = useState<Version>(undefined);

	useEffect(() => {
		if (loading)
			VersionApi.release(id, identifier.team_id)
				.then((v) => {
					if (v.id) setVersion(v);
					setLoading(false);
				})
				.catch((err) => {
					message.error(err.message);
					setLoading(false);
				});
	}, [loading]);

	useEffect(() => {
		setLoading(true);
		setVersion(undefined);
	}, [id]);

	return loading && version == undefined ? (
		<Spin />
	) : version ? (
		<VersionInfo
			version={version}
			onVersionChanged={(v) => {}}
			onNewVersionRequested={() => {}}
		/>
	) : (
		<Empty description={<span>Chưa có phiên bản trực tuyến nào</span>}></Empty>
	);
}
