import React, { useEffect, useState } from "react";
import { Layout, Row, Col, List, Avatar, message } from "antd";
import { useSelector } from "react-redux";

import { InboxApi } from "api";
import { InboxMessage, ApplicationState } from "schema/app";

import { Header, InboxList, InboxContent } from "../components";

export default function Inbox() {
	const [currentMessage, setCurrentMessage] = useState<InboxMessage>(undefined);

	const onClickItem = (ib: InboxMessage) => {
		setCurrentMessage(ib);
	};

	return (
		<Layout.Content style={{ padding: 50 }}>
			<Header title="Hộp thư" />
			<Row gutter={10} style={{ paddingTop: 24 }}>
				<Col span={12}>
					<InboxList onClickItem={onClickItem} />
				</Col>
				<Col span={12}>
					<InboxContent ib={currentMessage} />
				</Col>
			</Row>
		</Layout.Content>
	);
}
