import axios from "axios";

import { HOST } from "config/api";
import { Role, ErrorDescription } from "schema/app";

import { store } from "app-redux";

const TEAM_URL = "/v1/roles";

export const list = (page: number = 1, limit: number = 20, teamId?: string) => {
	const accessToken = store.getState().identifier?.access_token;
	let headers: { [key: string]: string } = {
		"X-Access-Token": accessToken as string,
	};
	if (teamId) headers["X-Team-Id"] = teamId;
	return axios
		.get(HOST + TEAM_URL, {
			params: {
				page,
				limit,
			},
			headers,
		})
		.then((response) => {
			if (response && response.data) {
				let invitations: Array<Role> = [];
				response.data.forEach((element: Role) => {
					invitations.push(element);
				});
				return invitations;
			}
			throw new Error("Cannot get roles");
		})
		.catch((error) => {
			throw new Error("Cannot get roles");
		});
};
