import React, { useState } from "react";
import moment from "moment";
import {
	Layout,
	Row,
	Col,
	List,
	Avatar,
	message,
	Typography,
	Button,
	Space,
} from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { SimpleInfo } from "uikit";
import { HOST } from "config/api";
import { UpdateIdentifier } from "app-redux/actions";
import { InboxMessage, ApplicationState } from "schema/app";

type Props = {
	ib?: InboxMessage;
};

export default function InboxContent({ ib }: Props) {
	const history = useHistory();
	const dispatch = useDispatch();
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const [loading, setLoading] = useState(false);

	const handleViewApp = (ib: InboxMessage) => {
		if (ib.team)
			if (identifier.team_id == ib.team.id) {
				history.push(`/dashboard/apps/${ib.application.id}?tab=version`);
			} else {
				setLoading(true);
				dispatch(UpdateIdentifier({ ...identifier, team_id: ib.team.id }));
				setTimeout(() => {
					history.push(`/dashboard/apps/${ib.application.id}?tab=version`);
				}, 200);
			}
		else {
			history.push("/admin/review");
		}
	};
	return ib ? (
		<Layout.Content style={{ backgroundColor: "#FFF" }}>
			<Row>
				<Col span={16}>
					<SimpleInfo title="Tiêu đề">
						<Typography.Title level={4}>
							{ib.subject}
							{ib.team ? ` - ${ib.team.name}` : ""}
						</Typography.Title>
					</SimpleInfo>
				</Col>
				<Col span={8} style={{ textAlign: "right" }}>
					<SimpleInfo
						title="Đã gửi"
						value={moment(ib.created_at).format("HH:mm - DD/MM/YYYY")}
					/>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Typography.Paragraph>{ib.content}</Typography.Paragraph>
					<Space>
						{ib.invoice && ib.invoice.pdf ? (
							<Button
								type="default"
								href={`${HOST}${ib.invoice.pdf}`}
								target="_blank"
								icon={<FileTextOutlined />}
							>
								Xem hóa đơn
							</Button>
						) : null}
						{ib.application ? (
							<Button
								type="primary"
								loading={loading}
								onClick={() => {
									handleViewApp(ib);
								}}
							>
								Xem ứng dụng
							</Button>
						) : null}
					</Space>
				</Col>
			</Row>
		</Layout.Content>
	) : (
		<Layout.Content style={{ backgroundColor: "#FFF" }}></Layout.Content>
	);
}
