import React from "react";

import { Avatar } from "antd";

import "./TeamItem.scss";

type Props = {
	name: string;
	icon?: React.ReactNode;
	onClick: () => void;
};

export default function TeamItem({ name, icon, onClick }: Props) {
	return (
		<li onClick={onClick} className="team-item">
			<Avatar size={120} icon={icon}>
				{name ? name.charAt(0).toUpperCase() : null}
			</Avatar>
			<h4>{name}</h4>
		</li>
	);
}
