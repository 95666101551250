import React from "react";
import { Tag } from "antd";
import {
	ClockCircleOutlined,
	SyncOutlined,
	ExclamationCircleOutlined,
	CloseCircleOutlined,
	CheckCircleOutlined,
} from "@ant-design/icons";

import { VersionStatusType } from "schema/app";

type Props = {
	status: VersionStatusType;
};

export default function VersionStatus({ status }: Props) {
	const tag = {
		color: "default",
		name: "Đang phát triển",
		icon: <ClockCircleOutlined />,
	};
	switch (status) {
		case "development":
			tag.color = "default";
			tag.name = "Đang phát triển";
			tag.icon = <ClockCircleOutlined />;
			break;
		case "submit for review":
			tag.color = "processing";
			tag.name = "Chờ kiểm duyệt";
			tag.icon = <ClockCircleOutlined />;
			break;
		case "in review":
			tag.color = "processing";
			tag.name = "Đang kiểm duyệt";
			tag.icon = <SyncOutlined spin />;
			break;
		case "need action":
			tag.color = "warning";
			tag.name = "Cần kiểm tra lại";
			tag.icon = <ExclamationCircleOutlined />;
			break;
		case "rejected":
			tag.color = "error";
			tag.name = "Bị từ chối";
			tag.icon = <CloseCircleOutlined />;
			break;
		case "approved":
			tag.color = "success";
			tag.name = "Đã chấp nhận";
			tag.icon = <CheckCircleOutlined />;
			break;
		case "archived":
			tag.color = "error";
			tag.name = "Đã gỡ";
			tag.icon = <CloseCircleOutlined />;
			break;
	}
	return (
		<Tag icon={tag.icon} color={tag.color}>
			{tag.name}
		</Tag>
	);
}
