import axios from "axios";

import { HOST } from "config/api";
import {
	User,
	ErrorDescription,
	Application,
	ReviewSession,
	Review,
} from "schema/app";

import { store } from "app-redux";
import request from "./request";

const SESSION_URL = "/v1/review_sessions";
const REVIEW_URL = "/v1/reviews";

export const createSession = (applicationId: string) =>
	request<ReviewSession>("POST", SESSION_URL, {
		application_id: applicationId,
	});

export const getSession = (sessionId: string) => {
	const accessToken = store.getState().identifier?.access_token;
	let headers: { [key: string]: string } = {
		"X-Access-Token": accessToken as string,
	};

	return axios
		.get(HOST + SESSION_URL + "/" + sessionId, {
			headers,
		})
		.then((response) => {
			if (response && response.data) {
				return response.data as ReviewSession;
			}
			throw new Error("Cannot create review session");
		});
};

export const updateSession = (
	sessionId: string,
	status: string,
	comment?: string
) =>
	request<ReviewSession>("PUT", SESSION_URL + "/" + sessionId, {
		status: status,
		comment,
	});

export const create = (sessionId: string, content: string, status: string) => {
	const accessToken = store.getState().identifier?.access_token;
	let headers: { [key: string]: string } = {
		"X-Access-Token": accessToken as string,
	};

	return axios
		.post(
			HOST + REVIEW_URL,
			{
				session_id: sessionId,
				content,
				status,
			},
			{
				headers,
			}
		)
		.then((response) => {
			if (response && response.data) {
				return response.data as Review;
			}
			throw new Error("Cannot create review session");
		});
};
