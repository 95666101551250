import React, { useEffect, useState } from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import {
	Row,
	Col,
	Typography,
	Statistic,
	Progress,
	Table,
	Button,
	Tag,
} from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { HOST } from "config/api";

import { ApplicationState, Invoice } from "schema/app";
import { InvoiceApi } from "api";
import { UpdateInvoices } from "app-redux/actions";

const columns = [
	{
		title: "Số hóa đơn",
		dataIndex: "id",
		key: "id",
		render: (value) => {
			return <Typography.Text>{value}</Typography.Text>;
		},
	},
	{
		title: "Thời gian",
		dataIndex: "created_at",
		key: "created_at",
		render: (value) => {
			return (
				<Typography.Text>
					{moment(value).format("DD/MM/YYYY hh:mm")}
				</Typography.Text>
			);
		},
	},
	{
		title: "Số tiền",
		dataIndex: "amount",
		key: "amount",
		render: (text) => (
			<NumberFormat
				value={text}
				displayType={"text"}
				thousandSeparator={true}
				suffix=" đ"
				renderText={(value) => <Typography.Text>{value}</Typography.Text>}
			/>
		),
	},
	{
		title: "Trạng Thái",
		dataIndex: "linked_transaction",
		key: "linked_transaction",
		render: (item) => {
			let color = "processing";
			let content = "Đang xử lý";
			switch (item.status) {
				case "created": {
					color = "orange";
					content = "Khởi tạo";
					break;
				}
				case "processing": {
					color = "processing";
					content = "Đang xử lý";
					break;
				}
				case "success": {
					color = "green";
					content = "Hoàn tất";
					break;
				}
				case "rejected": {
					color = "red";
					content = "Từ chối";
					break;
				}
				default:
					break;
			}
			return (
				<Tag color={color} key={item.status}>
					{content}
				</Tag>
			);
		},
	},
	{
		title: "Hóa đơn",
		dataIndex: "pdf",
		key: "pdf",
		render: (item) => (
			<Button
				disabled={!item}
				href={`${HOST}${item}`}
				target="_blank"
				icon={<FileTextOutlined />}
			>
				Xem hóa đơn
			</Button>
		),
	},
];

const PaymentTransaction = () => {
	const invoices = useSelector((state: ApplicationState) => state.invoices);
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const dispatch = useDispatch();

	useEffect(() => {
		InvoiceApi.list(1, 100).then((items: Invoice[]) => {
			dispatch(UpdateInvoices(items));
		});
	}, [identifier]);

	return (
		<div>
			<Typography.Title level={4}>Lịch sử thanh toán</Typography.Title>
			<Table columns={columns} dataSource={invoices} />
		</div>
	);
};
export default PaymentTransaction;
