import React, { useState, useEffect } from "react";
import { Empty, Button, Row, Col, message, Spin } from "antd";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import { VersionApi } from "api";
import { Version, ApplicationState } from "schema/app";

import CreateVersion from "./CreateVersion";
import VersionInfo from "./VersionInfo";

export default function DevelopmentSection() {
	const { id } = useParams();

	const identifier = useSelector((state: ApplicationState) => state.identifier);

	const [loading, setLoading] = useState(true);
	const [version, setVersion] = useState<Version>(undefined);
	const [showForm, setShowForm] = useState(false);

	useEffect(() => {
		if (loading)
			VersionApi.development(id, identifier.team_id)
				.then((v) => {
					if (v.id) setVersion(v);
					else {
						setVersion(undefined);
					}
					setLoading(false);
					setShowForm(false);
				})
				.catch((err) => {
					message.error(err.message);
					setLoading(false);
				});
	}, [loading]);

	useEffect(() => {
		setLoading(true);
		setShowForm(false);
		setVersion(undefined);
	}, [id]);

	return loading && version == undefined ? (
		<Spin />
	) : version ? (
		<VersionInfo
			version={version}
			onVersionChanged={(v) => {
				if (v) setVersion(v);
				else setLoading(true);
			}}
			onNewVersionRequested={() => {
				setVersion(undefined);
				setShowForm(true);
			}}
		/>
	) : showForm ? (
		<CreateVersion
			onVersionCreated={(v) => {
				setVersion(v);
			}}
		/>
	) : (
		<Empty description={<span>Chưa có phiên bản đang phát triển nào!</span>}>
			<Button type="primary" onClick={() => setShowForm(true)}>
				Tạo phiên bản ngay
			</Button>
		</Empty>
	);
}
