import { AnalyticsData } from "schema/app";

import request from "./request";

const PURCHASES_URL = "/v1/analytics/purchases";
const DAILY_PURCHASES_URL = "/v1/analytics/daily_purchases";

export const get = (start: number, end: number, application_id?: string) =>
	request<AnalyticsData>("GET", PURCHASES_URL, {
		start,
		end,
		application_id,
	});

export const getDaily = (start: number, end: number, application_id?: string) =>
	request<AnalyticsData>("GET", DAILY_PURCHASES_URL, {
		start,
		end,
		application_id,
	});

export const getTotalDaily = (
	start: number,
	end: number,
	application_id?: string
) =>
	request<AnalyticsData>("GET", DAILY_PURCHASES_URL, {
		start,
		end,
		application_id,
		cumulative: true,
	});
