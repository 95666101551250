import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	ApplicationState,
	Application,
	ApplicationFunction,
	ApplicationType,
} from "schema/app";
import { useParams } from "react-router-dom";
import {
	Row,
	Col,
	Typography,
	Switch,
	Upload,
	Button,
	Input,
	Select,
	Layout,
	Avatar,
	Tooltip,
	Spin,
	message,
	Divider,
} from "antd";
import { AppstoreOutlined, SaveOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";

import { UpdateUser, UpdateIdentifier } from "app-redux/actions";
import { HOST } from "config/api";
import { ApplicationApi } from "api";
import { UploadChangeParam } from "antd/lib/upload";
import { SimpleInfo } from "uikit";

import "./AppInfo.css";

const { Text } = Typography;

export default function AppInfo() {
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const dispatch = useDispatch();

	const { id } = useParams();

	const [loading, setLoading] = useState(false);
	const [edited, setEdited] = useState(false);
	const [uploading, setUploading] = useState(false);

	const [currentApp, setCurrentApp] = useState<Application>(undefined);
	const [appName, setAppName] = useState("");
	const [appType, setAppType] = useState<ApplicationType>("PERSONAL");
	const [appVisible, setAppVisible] = useState<"PUBLISHED" | "UNPUBLISHED">(
		"UNPUBLISHED"
	);
	const [appShortDes, setAppShortDes] = useState("");
	const [appDescription, setAppDescription] = useState("");
	const [appTargetWorkspace, setAppTargetWorkspace] = useState("");

	useEffect(() => {
		if (id && id != "new")
			ApplicationApi.get(id).then((application) => {
				if (application.team?.id == application.team.id) {
					setCurrentApp(application);
					setAppName(application.name);
					setAppType(application.application_type);
					setAppVisible(application.publish_status);
					setAppShortDes(application.short_description);
					setAppDescription(application.description);
					setAppTargetWorkspace(application.target_workspace);

					setEdited(false);
				}
			});
	}, [id, uploading]);

	useEffect(() => {
		setEdited(hasEdited());
	}, [
		appName,
		appType,
		appVisible,
		appShortDes,
		appDescription,
		appTargetWorkspace,
	]);

	const hasEdited = () => {
		if (currentApp == undefined) return false;
		return (
			appName != currentApp.name ||
			appType != currentApp.application_type ||
			appVisible != currentApp.publish_status ||
			appShortDes != currentApp.short_description ||
			appDescription != currentApp.description ||
			appTargetWorkspace != currentApp.target_workspace
		);
	};

	const saveApplication = () => {
		setLoading(true);
		ApplicationApi.createOrUpdate(
			{
				id: currentApp.id,
				name: appName,
				application_type: appType,
				publish_status: appVisible,
				short_description: appShortDes,
				description: appDescription,
				target_workspace: appTargetWorkspace,
			},
			identifier.team_id
		)
			.then((app) => {
				if (app.name != currentApp.name) {
					dispatch(UpdateIdentifier({ ...identifier }));
				}
				setLoading(false);
				setEdited(false);
				const newApp = { ...app, ...app.metadata_change };
				setCurrentApp(newApp);
			})
			.catch((err) => {
				message.error(err.message);
			});
	};

	return currentApp ? (
		<Layout.Content>
			<Row>
				<Col>
					<h4>Thông tin Ứng dụng</h4>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<SimpleInfo title="Tên ứng dụng">
						<Input
							type="text"
							value={appName}
							onChange={({ target }) => {
								setAppName(target.value);
							}}
						/>
					</SimpleInfo>

					<SimpleInfo title="Loại ứng dụng">
						<Select
							style={{ width: "100%", textAlign: "left" }}
							value={appType}
							onChange={(value: ApplicationType) => {
								setAppType(value);
							}}
						>
							<Select.Option value="PERSONAL">
								Public for All User
							</Select.Option>
							<Select.Option value="WORKSPACE">
								Public for All Workspace
							</Select.Option>
							<Select.Option value="WORKSPACE_ENTERPRISE">
								Private Workspace only
							</Select.Option>
						</Select>
					</SimpleInfo>
					{appType == "WORKSPACE_ENTERPRISE" ? (
						<SimpleInfo title="Workspace ID">
							<Input
								disabled={
									currentApp.target_workspace &&
									currentApp.target_workspace != ""
								}
								value={appTargetWorkspace}
								onChange={({ target }) => {
									setAppTargetWorkspace(target.value);
								}}
							/>
						</SimpleInfo>
					) : null}

					<SimpleInfo title="Giới thiệu ngắn">
						<Input.TextArea
							style={{ height: 100 }}
							value={appShortDes}
							onChange={({ target }) => {
								setAppShortDes(target.value);
							}}
						/>
					</SimpleInfo>
				</Col>
				<Col span={12}>
					<SimpleInfo title="Trạng thái">
						<Switch
							checkedChildren="Phát hành"
							unCheckedChildren="Ẩn"
							checked={appVisible == "PUBLISHED"}
							onChange={(value) => {
								setAppVisible(value == true ? "PUBLISHED" : "UNPUBLISHED");
							}}
						/>
					</SimpleInfo>
					<SimpleInfo title="Bundle ID">
						<Text copyable strong>
							{currentApp.bundle_id}
						</Text>
					</SimpleInfo>
					{currentApp.api_key ? (
						<SimpleInfo title="API Key">
							<Text copyable strong>
								{currentApp.api_key}
							</Text>
						</SimpleInfo>
					) : null}
					{currentApp.secret ? (
						<SimpleInfo title="Secret Key">
							<Text copyable strong>
								{currentApp.secret}
							</Text>
						</SimpleInfo>
					) : null}

					{currentApp.team ? (
						<SimpleInfo title="Team phát triển" value={currentApp.team.name} />
					) : null}

					<SimpleInfo title="Logo">
						<Upload
							name="application[logo]"
							action={`${HOST + ApplicationApi.APPLICATION_URL}/${
								currentApp.id
							}`}
							headers={{
								"X-Access-Token": identifier?.access_token as string,
							}}
							onChange={(info: UploadChangeParam) => {
								if (info.file.status === "done") {
									setUploading(false);
									dispatch(UpdateIdentifier({ ...identifier }));
								} else if (info.file.status === "error") {
									message.error("Fail to upload");
									setUploading(false);
								} else {
									setUploading(true);
								}
							}}
							method="PUT"
							showUploadList={false}
						>
							<Tooltip title="Bấm vào để thay đổi">
								{currentApp.logo && !uploading ? (
									<Avatar
										src={HOST + currentApp.logo}
										shape="square"
										size={120}
									/>
								) : (
									<Avatar
										icon={uploading ? <Spin /> : <AppstoreOutlined />}
										shape="square"
										size={120}
									/>
								)}
							</Tooltip>
						</Upload>
					</SimpleInfo>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<SimpleInfo title="Mô tả ứng dụng">
						<Editor
							apiKey="5visl2gxnzskc00rjlwo49o9r9q0ye78otz4riw2t0iwck6l"
							value={appDescription}
							init={{
								height: 300,
								menubar: false,
								plugins: [
									"advlist autolink lists link image charmap print preview anchor",
									"searchreplace visualblocks code fullscreen",
									"insertdatetime media table paste code help wordcount",
								],
								toolbar:
									"bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
							}}
							onEditorChange={(content) => {
								setAppDescription(content);
							}}
						/>
					</SimpleInfo>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col>
					<Button
						icon={<SaveOutlined />}
						type="primary"
						loading={loading}
						disabled={!edited}
						onClick={saveApplication}
					>
						Lưu thay đổi
					</Button>
				</Col>
			</Row>
		</Layout.Content>
	) : (
		<Spin />
	);
}
