import React, { useEffect, useState } from "react";
import { DatePicker, Switch, Modal, Input, Form, message } from "antd";
import { useParams } from "react-router-dom";

import { InboxApi } from "api";
import { InboxMessage } from "schema/app";

const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};

const SendMessageModal = ({ team_id, app_id, visible, onOk, onCancel }) => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	return (
		<Modal
			title="Gửi thông báo"
			centered
			visible={visible}
			destroyOnClose
			confirmLoading={loading}
			okText="Gửi"
			cancelText="Hủy"
			onOk={() => {
				setLoading(true);
				form
					.validateFields()
					.then((values) => {
						InboxApi.create({
							subject: values.subject,
							content: values.content,
							team_id: team_id,
							application_id: app_id,
						})
							.then((responseItem: InboxMessage) => {
								if (responseItem.id) {
									message.success("Đã gửi cảnh báo thành công!");
									form.resetFields();
									onOk();
								} else {
									message.error("Lỗi! Không thể gửi cảnh báo này");
								}

								setLoading(false);
							})
							.catch(() => {
								setLoading(false);
							});
					})
					.catch((info) => {
						console.log("Validate Failed:", info);
						setLoading(false);
					});
			}}
			onCancel={onCancel}
		>
			<Form
				form={form}
				initialValues={{
					subject: "",
					content: "",
				}}
			>
				<Form.Item
					{...formItemLayout}
					name={"subject"}
					label="Tiêu đề"
					rules={[{ required: true, message: "Vui lòng nhập tiêu đề!" }]}
				>
					<Input style={{ width: "100%" }} placeholder="Tiêu đề" />
				</Form.Item>
				<Form.Item
					{...formItemLayout}
					name={"content"}
					label="Nội dung"
					rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
				>
					<Input.TextArea
						style={{ width: "100%" }}
						placeholder="Nhập nội dung thông báo"
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default SendMessageModal;
