import { User, Application, Identifier, Team, Invoice } from "schema/app";
import {
	USER_UPDATE,
	TEAM_UPDATE,
	IDENTIFIER_UPDATE,
	TEAMS_UPDATE,
	INVOICES_UPDATE,
	INVOICE_ADD,
} from "./types";

export interface UserUpdate {
	type: USER_UPDATE;
	user: User | null;
}

export const UpdateUser = (user: User | null): UserUpdate => ({
	type: USER_UPDATE,
	user,
});

export interface TeamUpdate {
	type: TEAM_UPDATE;
	team: Team | null;
}

export const UpdateTeam = (team: Team | null): TeamUpdate => ({
	type: TEAM_UPDATE,
	team,
});

export interface IdentifierUpdate {
	type: IDENTIFIER_UPDATE;
	identifier: Identifier | null;
}

export const UpdateIdentifier = (
	identifier: Identifier | null
): IdentifierUpdate => ({
	type: IDENTIFIER_UPDATE,
	identifier,
});

export interface TeamsUpdate {
	type: TEAMS_UPDATE;
	teams: Array<Team>;
}

export const UpdateTeams = (teams: Array<Team>): TeamsUpdate => ({
	type: TEAMS_UPDATE,
	teams,
});

export interface InvoicesUpdate {
	type: INVOICES_UPDATE;
	invoices: Invoice[];
}

export const UpdateInvoices = (invoices: Invoice[]): InvoicesUpdate => ({
	type: INVOICES_UPDATE,
	invoices,
});

export interface InvoiceAdd {
	type: INVOICE_ADD;
	invoice: Invoice;
}

export const AddInvoice = (invoice: Invoice): InvoiceAdd => ({
	type: INVOICE_ADD,
	invoice,
});
