import React, { useEffect, useState } from "react";
import moment from "moment";
import {
	DatePicker,
	Switch,
	Modal,
	Input,
	Form,
	message,
	Space,
	Avatar,
	Descriptions,
	Typography,
	Table,
	Button,
} from "antd";
import { useParams } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";

import { HOST } from "config/api";
import { ApplicationApi } from "api";
import {
	ApplicationState,
	User,
	Application,
	Version,
	Team,
	LiteUser,
	ReviewSession,
	Review,
} from "schema/app";
import { VersionStatus } from "uikit";
import ViewMessagesModal from "./ViewMessagesModal";

const AppDetailsModal = ({ visible, application, onOk, onCancel }) => {
	const [loading, setLoading] = useState(false);
	const [detailsApp, setDetailsApp] = useState(application);
	const [showMessagesModal, setShowMessagesModal] = useState(false);
	const [selectedAppVersion, setSelectedAppVersion] = useState("");
	const [selectedMessages, setSelectedMessages] = useState([]);

	useEffect(() => {
		if (application.id) {
			setDetailsApp(application);
			ApplicationApi.get(application.id).then((app) => {
				console.log(app);
				setDetailsApp(app);
			});
		}
	}, [application]);

	return (
		<Modal
			title={
				<Space>
					<Avatar src={`${HOST}${detailsApp.logo}`} />
					<Typography.Text>{detailsApp.name}</Typography.Text>
				</Space>
			}
			width={1000}
			centered
			visible={visible}
			destroyOnClose
			confirmLoading={loading}
			okText="OK"
			cancelButtonProps={{ style: { display: "none" } }}
			onOk={onOk}
			onCancel={onCancel}
		>
			<div style={{ height: 600, overflow: "auto" }}>
				<Typography.Title level={3}>Thông tin cơ bản</Typography.Title>
				<Descriptions bordered>
					<Descriptions.Item label="Tên">{detailsApp.name}</Descriptions.Item>
					<Descriptions.Item label="Bundle Id">
						{detailsApp.bundle_id}
					</Descriptions.Item>
					<Descriptions.Item label="Phiên bản">
						{detailsApp.version}
					</Descriptions.Item>
					<Descriptions.Item label="Dev Team" span={3}>
						{detailsApp.team ? detailsApp.team.name : ""}
					</Descriptions.Item>
					<Descriptions.Item label="Mô tả" span={3}>
						<div
							dangerouslySetInnerHTML={{ __html: detailsApp.description }}
						></div>
					</Descriptions.Item>
					<Descriptions.Item label="Mô tả ngắn" span={3}>
						{detailsApp.short_description}
					</Descriptions.Item>
					<Descriptions.Item label="Mô tả ngắn" span={3}>
						{detailsApp.short_description}
					</Descriptions.Item>
				</Descriptions>
				<Typography.Title level={3}>Lịch sử xét duyệt</Typography.Title>
				<Table
					dataSource={detailsApp.review_sessions ?? []}
					columns={[
						{
							title: "Version",
							key: "version",
							dataIndex: "version",
							render: (version: Version) => {
								return <span>{version.name}</span>;
							},
						},
						{
							title: "Trạng thái",
							key: "status",
							width: 140,
							render: (reviewSession: ReviewSession) => {
								const stt = reviewSession.status;
								return <VersionStatus status={stt} />;
							},
						},
						{
							title: "Cập nhật lần cuối",
							key: "updated_at",
							dataIndex: "updated_at",
							render: (updated_at: number) => {
								return <span>{moment(updated_at).fromNow()}</span>;
							},
							sorter: {
								compare: (a: Application, b: Application) =>
									b.updated_at - a.updated_at,
								multiple: 1,
							},
						},
						{
							title: "Người kiểm duyệt",
							key: "reviewer",
							dataIndex: "reviewer",
							render: (reviewer: LiteUser) => {
								return <span>{reviewer.name}</span>;
							},
						},
						{
							title: "",
							key: "action",
							fixed: "right",
							render: (record: ReviewSession) => {
								return (
									<Button
										disabled={
											record.reviews === null ||
											record.reviews === undefined ||
											record.reviews.length === 0
										}
										type="primary"
										onClick={() => {
											setShowMessagesModal(true);
											setSelectedAppVersion(record.version.name);
											setSelectedMessages(record.reviews);
										}}
									>
										Xem tin nhắn
									</Button>
								);
							},
						},
					]}
					pagination={false}
					rowKey={(record) => record.id}
				/>
			</div>
			<ViewMessagesModal
				app={detailsApp}
				appVersion={selectedAppVersion}
				appMessages={selectedMessages}
				visible={showMessagesModal}
				onOk={() => {
					setShowMessagesModal(false);
				}}
				onCancel={() => {
					setShowMessagesModal(false);
				}}
			/>
		</Modal>
	);
};

export default AppDetailsModal;
