import React, { useEffect, useState } from "react";
import {
	Input,
	Select,
	Typography,
	Modal,
	InputNumber,
	Form,
	message,
} from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";

import { SupportApi, ApplicationApi, InvoiceApi } from "api";
import {
	SupportTicket,
	ApplicationState,
	Application,
	Invoice,
} from "schema/app";
import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;
const { Text } = Typography;
const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};

const CreateSupportTicketModal = ({ visible, onOk, onCancel }) => {
	const [form] = Form.useForm();
	const { id } = useParams();

	const [loading, setLoading] = useState(false);
	const [applications, setApplications] = useState([]);
	const [invoices, setInvoices] = useState([]);
	const [ticketType, setTicketType] = useState("general");

	const identifier = useSelector((state: ApplicationState) => state.identifier);

	useEffect(() => {
		Promise.all([
			ApplicationApi.list(1, 100, identifier.team_id),
			InvoiceApi.list(1, 100),
		]).then((values) => {
			const applications = values[0];
			const invoices = values[1];

			setApplications(applications);
			setInvoices(invoices);
		});
	}, []);

	return (
		<Modal
			title="Tạo yêu cầu hỗ trợ"
			centered
			destroyOnClose
			confirmLoading={loading}
			visible={visible}
			onOk={() => {
				setLoading(true);
				form
					.validateFields()
					.then((values) => {
						SupportApi.create({
							ticket_type: values.ticket_type,
							subject: values.subject,
							content: values.content,
							application_id: values.application_id,
							invoice_id: values.invoice_id,
						})
							.then((item: SupportTicket) => {
								if (item.id) {
									onOk(item);
									form.resetFields();
								} else {
									message.error("Lỗi! Không thể tạo sản phẩm này");
								}
								setLoading(false);
							})
							.catch((error) => {
								setLoading(false);
							});
					})
					.catch((info) => {
						console.log("Validate Failed:", info);
						setLoading(false);
					});
			}}
			onCancel={onCancel}
		>
			<Form
				form={form}
				initialValues={{
					ticket_type: "general",
				}}
			>
				<Form.Item {...formItemLayout} name="ticket_type" label="Loại">
					<Select
						onChange={(value) => {
							setTicketType(value.toString());
						}}
					>
						<Option value="general" key="general">
							Tổng quát
						</Option>
						<Option value="application" key="application">
							Ứng dụng
						</Option>
						<Option value="invoice" key="invoice">
							Hóa đơn
						</Option>
					</Select>
				</Form.Item>
				<Form.Item
					{...formItemLayout}
					name="subject"
					label="Tiêu đề"
					rules={[{ required: true, message: "Vui lòng nhập tiêu đề!" }]}
				>
					<Input type="text" placeholder="Nhập tiêu đề"></Input>
				</Form.Item>
				<Form.Item
					{...formItemLayout}
					name="content"
					label="Nội dung"
					rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
				>
					<Input.TextArea placeholder="Nhập nội dung"></Input.TextArea>
				</Form.Item>
				{ticketType === "application" ? (
					<Form.Item
						{...formItemLayout}
						name="application_id"
						label="Ứng dụng"
						rules={[{ required: true, message: "Vui lòng chọn ứng dụng!" }]}
					>
						<Select>
							{applications.map((item: Application) => {
								return (
									<Option value={item.id} key={item.id}>
										{item.name}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				) : null}
				{ticketType === "invoice" ? (
					<Form.Item
						{...formItemLayout}
						name="invoice_id"
						label="Hóa đơn"
						rules={[{ required: true, message: "Vui lòng chọn hóa đơn!" }]}
					>
						<Select>
							{invoices.map((item: Invoice) => {
								return (
									<Option value={item.id} key={item.id}>
										{`Hóa Đơn # ${item.id} @ ${moment(item.created_at).format(
											"DD/MM/YYYY"
										)}`}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				) : null}
			</Form>
		</Modal>
	);
};

export default CreateSupportTicketModal;
