import React, { Component } from "react";
import { Menu, Dropdown, Input, Carousel, Modal, Button } from "antd";
import "antd/dist/antd.css";
import "./Home.css";
import { PublicHeader, PublicFooter, IntroSlider } from "../components";

class Home extends Component {
	render() {
		return (
			<div className="page">
				<PublicHeader />
				<IntroSlider />

				<section className="section">
					<div className="container">content here</div>
				</section>
				<PublicFooter />
			</div>
		);
	}
}

export default Home;
