import React, { useState } from "react";
import {
	Button,
	Modal,
	Form,
	Input,
	Radio,
	Divider,
	Typography,
	message,
} from "antd";
import { useSelector } from "react-redux";

import { ApplicationState, Team } from "schema/app";
import { TeamApi } from "api";

type Props = {
	visible: boolean;
	paymentProfile?: { [key: string]: any };
	onTeamUpdated: (team: Team) => void;
	onCancel: () => void;
};

export default function PaymentProfileModal({
	visible,
	paymentProfile,
	onTeamUpdated,
	onCancel,
}: Props) {
	const identifier = useSelector((state: ApplicationState) => state.identifier);

	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const updatePaymentInfo = (values) => {
		setLoading(true);
		const payment_info = {};
		Object.keys(values).forEach((k) => {
			const v = values[k];
			if (v) {
				const [k1, k2] = k.split("-");
				if (k1 && k2) {
					if (payment_info[k1]) {
						payment_info[k1][k2] = v;
					} else {
						payment_info[k1] = {
							[k2]: v,
						};
					}
				}
			}
		});

		TeamApi.update({ id: identifier.team_id, payment_info })
			.then((team) => {
				setLoading(false);
				onTeamUpdated(team);
			})
			.catch((err) => {
				message.error(err.message);
				setLoading(false);
			});
	};

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				updatePaymentInfo(values);
			})
			.catch((info) => {
				console.log("Validate Failed:", info);
			});
	};

	const initialValues = {};

	if (paymentProfile) {
		Object.keys(paymentProfile).forEach((k) => {
			Object.keys(paymentProfile[k]).forEach((s) => {
				initialValues[`${k}-${s}`] = paymentProfile[k][s];
			});
		});
	}

	return (
		<Modal
			visible={visible}
			title="Thông tin thanh toán"
			okText="Cập nhật"
			cancelText="Bỏ qua"
			onCancel={() => {
				form.resetFields();
				onCancel();
			}}
			onOk={onOk}
			confirmLoading={loading}
			destroyOnClose
		>
			<Form form={form} layout="vertical" initialValues={initialValues}>
				<Form.Item
					name="profile-name"
					label="Tên Công ty hoặc Cá nhân"
					rules={[
						{
							required: true,
							message: "Vui lòng nhập tên công ty hoặc cá nhân",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="profile-tax_number" label="Mã số thuế">
					<Input />
				</Form.Item>
				<Form.Item
					name="profile-address"
					label="Địa chỉ"
					rules={[
						{
							required: true,
							message: "Vui lòng nhập địa chỉ tối thiểu 8 kí tự",
							min: 8,
						},
					]}
				>
					<Input.TextArea />
				</Form.Item>
				<Divider />
				<Typography.Text>Thông tin thanh toán</Typography.Text>
				<Form.Item
					name="account-name"
					label="Tên tài khoản"
					rules={[
						{
							required: true,
							message: "Vui lòng nhập tên tài khoản",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="account-number"
					label="Số tài khoản"
					rules={[
						{
							required: true,
							message: "Vui lòng nhập số tài khoản",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="account-bank_name"
					label="Ngân hàng"
					rules={[
						{
							required: true,
							message: "Vui lòng nhập tên ngân hàng",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="account-bank_address"
					label="Địa chỉ Ngân hàng (nếu có)"
				>
					<Input.TextArea />
				</Form.Item>
			</Form>
		</Modal>
	);
}
