import React from "react";
import { Menu, Dropdown, Input } from "antd";
import "antd/dist/antd.css";
import "./PublicHeader.css";

const { Search } = Input;

const PublicHeader = () => (
	<section className="header">
		<nav className="navbar navbar-expand-lg ">
			<button
				className="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarTogglerDemo03"
				aria-controls="navbarTogglerDemo03"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon">
					<img src={require("../images/iconmenu.svg")} height={30} />
				</span>
			</button>
			<a className="navbar-brand" href="/">
				<img src={require("../images/logo.png")} height={35} />
			</a>
			<div className="collapse navbar-collapse" id="navbarTogglerDemo03">
				<ul className="navbar-nav ml-auto mt-2 mt-lg-0  mr-3">
					<li className="nav-item">
						<a
							className="nav-link"
							href="https://github.com/ACheckin/acheckin-sdk"
							target="_blank"
						>
							Tài liệu
						</a>
					</li>

					<li className="nav-item">
						<a className="nav-link" href="login">
							Đăng nhập
						</a>
					</li>

					{/* <li className="nav-item">
						<a className="nav-link " href="#">
							<Dropdown overlay={this.renderMenu()}>
								<a className="ant-dropdown-link">
									Ứng dụng của tôi <Icon type="caret-down" />
								</a>
							</Dropdown>
						</a>
					</li> */}
				</ul>

				<form className="form-inline my-2 my-lg-0">
					<Search placeholder="Tìm kiếm tài liệu" />
				</form>
			</div>
		</nav>
	</section>
);

export default PublicHeader;
