import React, { useState } from "react";
import { Menu, Layout, Divider } from "antd";
import {
	HomeOutlined,
	LineChartOutlined,
	AppstoreOutlined,
	TeamOutlined,
	InfoCircleOutlined,
	FileTextOutlined,
	QuestionCircleOutlined,
	WalletOutlined,
	GroupOutlined,
} from "@ant-design/icons";

import { useHistory, useRouteMatch } from "react-router";
import { useSelector } from "react-redux";
import { ApplicationState } from "schema/app";

export default function Sidebar() {
	const history = useHistory();
	const match = useRouteMatch();

	const team = useSelector((state: ApplicationState) => state.team);

	let selectedKey = history.location.pathname
		.replace("/dashboard", "")
		.replace("/", "");
	if (selectedKey == "") {
		selectedKey = "dashboard";
	}

	if (selectedKey.indexOf("apps") != -1) {
		selectedKey = "apps";
	}

	const [collapsed, setCollapsed] = useState(false);
	const onCollapse = (collapsed) => {
		setCollapsed(collapsed);
	};

	return (
		<Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
			<Menu
				selectedKeys={[selectedKey]}
				onSelect={(params) => {
					const { key } = params;
					if (key == "dashboard") {
						history.push(match.url);
						return;
					}
					const urlToGo = `${match.url}/${key}`;
					history.push(urlToGo);
				}}
			>
				<Menu.Item key="analytics">
					<LineChartOutlined />
					<span>Tổng quát</span>
				</Menu.Item>
				<Menu.Item key="info">
					<InfoCircleOutlined />
					<span>Thông tin team</span>
				</Menu.Item>
				{team && team.role?.name == "Team Agent" ? (
					<Menu.Item key="people">
						<TeamOutlined />
						<span>Thành viên</span>
					</Menu.Item>
				) : null}
				<Menu.Item key="apps">
					<AppstoreOutlined />
					<span>Quản lý ứng dụng</span>
				</Menu.Item>
				{team && team.role?.name == "Team Agent" ? (
					<Menu.Item key="payment">
						<WalletOutlined />
						<span>Thanh toán</span>
					</Menu.Item>
				) : null}

				<Menu.Item key="documents">
					<a href="https://github.com/ACheckin/acheckin-sdk" target="_blank">
						<FileTextOutlined />
						<span>Tài liệu</span>
					</a>
				</Menu.Item>

				<Menu.Item key="support">
					<QuestionCircleOutlined />
					<span>Hỗ trợ</span>
				</Menu.Item>
			</Menu>
		</Layout.Sider>
	);
}
