import { combineReducers } from "redux";

import { ApplicationState } from "schema/app";

import user from "./user";
import identifier from "./identifier";
import team from "./team";
import teams from "./teams";
import invoices from "./invoices";

export default combineReducers<ApplicationState>({
	user,
	identifier,
	team,
	teams,
	invoices,
});
