import React from "react";
import { Table, Select, Row, Col, Button, Form, Typography } from "antd";

import { Role } from "schema/app";

type Props = {
	roles?: Array<Role>;
	defaultRole: Role;
	disabled?: boolean;
	loading: boolean;
	onRoleChanged: (r: Role) => void;
};

export default function RoleSelector({
	roles,
	defaultRole,
	disabled,
	loading,
	onRoleChanged,
}: Props) {
	return (
		<Select
			defaultValue={defaultRole.id}
			disabled={disabled == true}
			style={{ width: "100%" }}
			onChange={(value: string) => {
				onRoleChanged(roles.find((r) => r.id == value));
			}}
			optionLabelProp="title"
			loading={loading}
		>
			{roles &&
				roles.map((role) => {
					return (
						<Select.Option value={role.id} key={role.id} title={role.name}>
							{role.name}
						</Select.Option>
					);
				})}
		</Select>
	);
}
