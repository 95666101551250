import axios from "axios";

import { HOST } from "config/api";
import { ErrorDescription, Team } from "schema/app";

import { store } from "app-redux";
import request from "./request";

const TEAM_URL = "/v1/teams";

export const list = () => request<Array<Team>>("GET", TEAM_URL);

export const create = (name: string, workspace_id?: string) =>
	request<Team>("POST", TEAM_URL, {
		name,
		workspace_id,
	});

export const update = (team: Team) =>
	request<Team>("PUT", TEAM_URL + "/" + team.id, team);

export const get = (team_id: string) =>
	request<Team>("GET", TEAM_URL + "/" + team_id);
