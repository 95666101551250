import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import {
	Table,
	Button,
	Empty,
	Spin,
	Row,
	Col,
	Tag,
	Typography,
	Collapse,
	Input,
	Card,
	message,
	Avatar,
	Space,
	Comment,
	Tooltip,
	Form,
} from "antd";
import {
	AppstoreOutlined,
	CheckOutlined,
	StopOutlined,
	InfoCircleTwoTone,
} from "@ant-design/icons";

import {
	ApplicationState,
	User,
	Application,
	Version,
	ReviewSession,
	Review,
} from "schema/app";
import { ReviewApi } from "api";
import { HOST } from "config/api";
import { SimpleInfo } from "uikit";

type Props = {
	app?: Application;
	onReviewSessionChanged: (rv: ReviewSession) => void;
};

export default function ReviewSessionComponent({
	app,
	onReviewSessionChanged,
}: Props) {
	const [session, setSession] = useState<ReviewSession>(undefined);
	const bottomDiv = useRef(undefined);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const getSession = () => {
		if (app == undefined) {
			setSession(undefined);
			return;
		}
		ReviewApi.getSession(app.review_session.id)
			.then((review_session) => {
				setSession(review_session);
			})
			.catch((err) => {
				message.error(err.message);
			});
	};

	useEffect(() => {
		setSession(undefined);
		getSession();
		console.log(app);
	}, [app]);

	const scrollToBottom = () => {
		bottomDiv.current.scrollIntoView({ behavior: "smooth" });
	};

	const onFinish = (values) => {
		setLoading(true);
		const { comment } = values;
		ReviewApi.updateSession(session.id, "need action", comment)
			.then((s) => {
				setLoading(false);
				setSession(s);
				onReviewSessionChanged(s);
				form.resetFields();
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};

	const onFinishFailed = (errorInfo) => {};

	const handleChangeStatus = (status) => {
		setLoading(true);
		ReviewApi.updateSession(session.id, status)
			.then((s) => {
				setLoading(false);
				if (status == "rejected") {
					setSession(undefined);
				} else {
					setSession(s);
				}
				onReviewSessionChanged(s);
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};

	// handleCollapseCallback = (key: string | string[]) => {};

	// handleApproveApplication = (sessionId: string) => {
	// 	ReviewApi.updateSession(sessionId, "approved").then((session) => {
	// 		this.setState({ session });
	// 	});
	// };

	// handleCommentOnApplication = (sessionId: string) => {
	// 	ReviewApi.updateSession(sessionId, "need action", this.state.comment).then(
	// 		(session) => {
	// 			this.setState({ session, reviews: session.reviews, comment: "" });
	// 		}
	// 	);
	// };

	// renderCommentForm = () => {
	// 	const { comment } = this.state;
	// 	return (
	// 		<div>
	// 			<h4 style={{ marginTop: 20 }}>Add Comment</h4>
	// 			<Input.TextArea
	// 				style={{ marginTop: 0, marginBottom: 20 }}
	// 				placeholder="Input your comment here"
	// 				rows={10}
	// 				value={comment}
	// 				onChange={({ target }) => {
	// 					this.setState({ comment: target.value });
	// 				}}
	// 			></Input.TextArea>
	// 		</div>
	// 	);
	// };
	// renderReview = (review: Review) => {
	// 	return (
	// 		<Card
	// 			style={{ marginTop: 16 }}
	// 			type="inner"
	// 			title={`${review.reviewer.name} [${review.review_type}]`}
	// 		>
	// 			{review.content}
	// 		</Card>
	// 	);
	// };

	return session ? (
		<div>
			<Collapse
				defaultActiveKey={["application", "comments"]}
				onChange={(key) => {}}
			>
				<Collapse.Panel header="Thông tin ứng dụng" key="application">
					<Row>
						<Col span={12}>
							<SimpleInfo
								title="Tên ứng dụng"
								showTip={
									app.metadata_change !== null &&
									app.metadata_change.name !== null
								}
								tip={app.name}
							>
								<Typography.Text style={{ fontWeight: "bold" }}>
									{app.metadata_change && app.metadata_change.name
										? app.metadata_change.name
										: app.name}
								</Typography.Text>
							</SimpleInfo>
							<SimpleInfo
								title="Bundle ID"
								showTip={
									app.metadata_change !== null &&
									app.metadata_change.bundle_id !== null
								}
								tip={app.bundle_id}
							>
								<Space>
									<Typography.Text style={{ fontWeight: "bold" }}>
										{app.metadata_change && app.metadata_change.bundle_id
											? app.metadata_change.bundle_id
											: app.bundle_id}
									</Typography.Text>
								</Space>
							</SimpleInfo>
							<SimpleInfo
								title="Phiên bản"
								value={
									app.review_version?.name ||
									app.review_session?.application.review_version?.name
								}
							/>
							<SimpleInfo
								title="Giới thiệu ngắn"
								showTip={
									app.metadata_change != null &&
									app.metadata_change.short_description != null
								}
								tip={app.short_description}
							>
								<Space>
									<Typography.Text style={{ fontWeight: "bold" }}>
										{app.metadata_change &&
										app.metadata_change.short_description
											? app.metadata_change.short_description
											: app.short_description}
									</Typography.Text>
								</Space>
							</SimpleInfo>
							<SimpleInfo
								title="Mô tả ứng dụng"
								showTip={
									app.metadata_change != null &&
									app.metadata_change.description != null
								}
								tip={app.description}
							>
								<Space style={{ justifyItems: "center" }}>
									<div
										dangerouslySetInnerHTML={{
											__html: `${
												app.metadata_change && app.metadata_change.description
													? app.metadata_change.description
													: app.description
											}`,
										}}
									/>
								</Space>
							</SimpleInfo>
						</Col>

						<Col span={12}>
							<SimpleInfo title="Nhà phát triển" value={app.team?.name} />
							<SimpleInfo
								title="Loại ứng dụng"
								showTip={
									app.metadata_change != null &&
									app.metadata_change.application_type != null
								}
								tip={app.application_type}
							>
								<Space>
									<>
										<Space>
											<Tag color="blue">
												{app.metadata_change &&
												app.metadata_change.application_type
													? app.metadata_change.application_type
													: app.application_type}
											</Tag>
										</Space>
									</>
								</Space>
							</SimpleInfo>
							{app.target_workspace ||
							(app.metadata_change && app.metadata_change.target_workspace) ? (
								<SimpleInfo
									title="Workspace: "
									showTip={
										app.metadata_change != null &&
										app.metadata_change.target_workspace != null
									}
									tip={app.target_workspace}
								>
									<Space>
										<Typography.Text strong>
											{app.metadata_change &&
											app.metadata_change.target_workspace
												? app.metadata_change.target_workspace
												: app.target_workspace}
										</Typography.Text>
									</Space>
								</SimpleInfo>
							) : null}
							{app.metadata_change && app.metadata_change.logo ? (
								<SimpleInfo title="App Icon mới">
									{app.logo ? (
										<Avatar src={HOST + app.logo} shape="square" size={48} />
									) : (
										<Typography.Text>Chưa có icon</Typography.Text>
									)}
								</SimpleInfo>
							) : null}
							<SimpleInfo
								title={
									app.metadata_change && app.metadata_change.logo
										? "App Icon cũ"
										: "App Icon"
								}
							>
								{app.logo ? (
									<Avatar src={HOST + app.logo} shape="square" size={48} />
								) : (
									<Typography.Text>Chưa có icon</Typography.Text>
								)}
							</SimpleInfo>
						</Col>
					</Row>
				</Collapse.Panel>
				<Collapse.Panel header="Lời nhắn" key="comments">
					<div style={{ maxHeight: 200, overflow: "auto" }}>
						{session.reviews
							.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
							.map((r) => {
								return (
									<Comment
										key={r.id}
										author={<a>{r.reviewer?.name ?? "ACheckin Team"}</a>}
										avatar={
											<Avatar>
												{r.reviewer?.name.charAt(0).toUpperCase() ?? "A"}
											</Avatar>
										}
										content={<p>{r.content}</p>}
										datetime={
											<Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
												<span>{moment(r.created_at).fromNow()}</span>
											</Tooltip>
										}
									/>
								);
							})}

						<div ref={bottomDiv} />
					</div>
					<Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
						<Form.Item
							name="comment"
							rules={[
								{
									required: true,
									message: "Vui lòng nhập lời nhắn tối thiểu 10 kí tự!",
									min: 10,
								},
							]}
						>
							<Input.TextArea />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit" loading={loading}>
								Gửi
							</Button>
						</Form.Item>
					</Form>

					<Space>
						<Button
							type="primary"
							disabled={session.opened_at === undefined}
							icon={<CheckOutlined />}
							loading={loading}
							onClick={() => {
								handleChangeStatus("approved");
							}}
						>
							Chấp nhận
						</Button>
						<Button
							type="primary"
							danger
							icon={<StopOutlined />}
							loading={loading}
							onClick={() => {
								handleChangeStatus("rejected");
							}}
						>
							Từ chối
						</Button>
					</Space>
				</Collapse.Panel>
			</Collapse>
			{/* {["approved", "rejected"].indexOf(session.status) == -1 ? (
					this.renderCommentForm()
				) : (
					<Tag color="gray">{session.status}</Tag>
				)}
				{["approved", "rejected"].indexOf(session.status) == -1
					? this.renderActions()
					: null} */}
		</div>
	) : (
		<Spin />
	);
}
