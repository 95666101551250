import React, { useState } from "react";
import {
	Row,
	Col,
	Statistic,
	Upload,
	Tooltip,
	Button,
	message,
	Layout,
	Space,
} from "antd";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import { VersionApi } from "api";
import { SimpleInfo, VersionStatus } from "uikit";
import { Version, ApplicationState } from "schema/app";
import { HOST } from "config/api";

import ReviewSession from "./ReviewSession";

type Props = {
	version: Version;
	onVersionChanged: (v?: Version) => void;
	onNewVersionRequested: () => void;
};

export default function VersionInfo({
	version,
	onVersionChanged,
	onNewVersionRequested,
}: Props) {
	const { id } = useParams();
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const [uploading, setUploading] = useState(false);
	const [loading, setLoading] = useState(false);

	const submitForReview = () => {
		setLoading(true);
		VersionApi.submitForReview(id, version)
			.then((v) => {
				setLoading(false);
				onVersionChanged(v);
			})
			.catch((error) => {
				message.error(error.message);
				setLoading(false);
			});
	};

	const cancelSubmitForReview = () => {
		setLoading(true);
		VersionApi.cancelSubmitForReview(id, version)
			.then((v) => {
				setLoading(false);
				onVersionChanged(v);
			})
			.catch((error) => {
				message.error(error.message);
				setLoading(false);
			});
	};

	const archiveVersion = () => {
		setLoading(true);
		VersionApi.archiveVersion(id, version)
			.then((v) => {
				setLoading(false);
				onVersionChanged(undefined);
			})
			.catch((error) => {
				message.error(error.message);
				setLoading(false);
			});
	};

	return (
		<Layout.Content>
			<Row>
				<Col span={8}>
					<Statistic title="Phiên bản" value={version.name} />
					<SimpleInfo title="Gói cài đặt">
						{version.status == "development" ? (
							<Upload
								name="version[installation_package]"
								action={
									HOST +
									VersionApi.VERSIONS_URL.replace(":application_id", id) +
									"/" +
									version.id
								}
								headers={{
									"X-Access-Token": identifier.access_token,
								}}
								onChange={(info) => {
									if (info.file.status === "done") {
										setUploading(false);
										onVersionChanged();
										message.success("Tải lên thành công!");
									} else if (info.file.status === "error") {
										message.error("Tải lên thất bại, vui lòng thử lại!");
										setUploading(false);
									} else {
										setUploading(true);
									}
								}}
								method="PUT"
								showUploadList={false}
							>
								<Tooltip title="Bấm vào để thay đổi">
									<Button type="default" loading={uploading}>
										{version.installation_package
											? version.installation_package?.replace(/^.+[\/]/, "")
											: "Tải lên"}
									</Button>
								</Tooltip>
							</Upload>
						) : (
							<Button type="default" loading={uploading}>
								{version.installation_package
									? version.installation_package?.replace(/^.+[\/]/, "")
									: "Tải lên"}
							</Button>
						)}
					</SimpleInfo>
				</Col>
				<Col span={16}>
					<SimpleInfo title="Trạng thái">
						<VersionStatus status={version.status} />
					</SimpleInfo>
					<Statistic
						title="Mô tả"
						value={version.description ?? "Chưa có mô tả"}
					/>
				</Col>
			</Row>
			{version.review_session && version.status != "approved" ? (
				<ReviewSession
					version={version}
					onReviewSessionChanged={(s) => {
						onVersionChanged({ ...version, review_session: s });
					}}
				/>
			) : null}
			<Row>
				<Col span={24}>
					<Space>
						{version.status == "development" ? (
							<Button
								type="primary"
								loading={loading}
								disabled={
									version.name.includes("meta")
										? false
										: version.installation_package == undefined
								}
								onClick={submitForReview}
							>
								{version.application &&
								version.application.application_type == "WORKSPACE_ENTERPRISE"
									? "Phát hành phiên bản"
									: "Gửi yêu cầu Kiểm duyệt"}
							</Button>
						) : null}
						{version.status == "development" ? (
							<Button
								type="primary"
								danger
								loading={loading}
								onClick={archiveVersion}
							>
								Xóa phiên bản
							</Button>
						) : null}
						{version.status == "submit for review" ? (
							<Button
								type="primary"
								danger
								loading={loading}
								onClick={cancelSubmitForReview}
							>
								Không kiểm duyệt nữa
							</Button>
						) : null}
						{version.status == "rejected" ? (
							<Button type="primary" onClick={() => onNewVersionRequested()}>
								Tạo phiên bản mới
							</Button>
						) : null}
					</Space>
				</Col>
			</Row>
		</Layout.Content>
	);
}
