import React, { useState, useEffect } from "react";
import { Row, Col, Table, Typography, Button, message, Avatar } from "antd";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import {
	Invitation,
	ApplicationState,
	Role,
	TeamMember,
	User,
} from "schema/app";
import { InvitationApi, RoleApi } from "api";
import { useSelector } from "react-redux";

import { HOST } from "config/api";
import RoleSelector from "../components/RoleSelector";
import SearchUser from "../components/SearchUser";

function RenderColName(user: TeamMember, row: Invitation, index: number) {
	return (
		<Row gutter={16}>
			<Col>
				<Avatar size={"large"} src={user.picture} icon={<UserOutlined />} />
			</Col>
			<Col>
				<h6>{user.name}</h6>
				<span>{user.email ?? "-"}</span>
			</Col>
		</Row>
	);
}

type Props = {
	type: "administration" | "team";
};

export default function TeamManagement({ type }: Props) {
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const team = useSelector((state: ApplicationState) => state.team);
	const user = useSelector((state: ApplicationState) => state.user);

	const [loading, setLoading] = useState(true);
	const [rowLoading, setRowLoading] = useState<number>(undefined);
	const [invitations, setInvitations] = useState<Array<Invitation>>(undefined);
	const [roles, setRoles] = useState<Array<Role>>(undefined);

	const teamId = type == "administration" ? type : team.id;

	const removeInvitation = (invitation: Invitation, index: number) => {
		setRowLoading(index);
		InvitationApi.remove(invitation, teamId)
			.then((invitation) => {
				const newInvitations = [...invitations];
				newInvitations.splice(index, 1);
				setInvitations(newInvitations);
				setRowLoading(undefined);
			})
			.catch((err) => {
				message.error(err.message);
				setRowLoading(undefined);
			});
	};

	const updateInvitation = (invitation: Invitation, index: number) => {
		setRowLoading(index);
		InvitationApi.createOrUpdate(invitation, teamId)
			.then((i) => {
				setRowLoading(undefined);
			})
			.catch((err) => {
				message.error(err.message);
				setRowLoading(undefined);
				setInvitations(invitations);
			});
	};

	const addUser = (u: User) => {
		const testerRole =
			type == "administration"
				? roles.find((r) => r.name == "Reviewer")
				: roles.find((r) => r.name == "Tester");
		if (testerRole) {
			const key = "add-user";
			message.loading({ content: "Đang thêm thành viên...", key });
			InvitationApi.createOrUpdate(
				{
					user: {
						id: u.id,
						email: u.email,
						name: u.name,
						username: u.username,
						picture: u.picture,
					},
					role: testerRole,
				},
				teamId
			)
				.then((i) => {
					message.success({ content: "Thêm thành công!", key });
					setInvitations([...invitations, i]);
				})
				.catch((err) => {
					message.error({ content: err.message, key });
				});
		}
	};

	const columns = [
		{
			title: "Thành viên",
			dataIndex: "user",
			render: RenderColName,
		},
		{
			width: "200px",
			title: "Vị trí",
			dataIndex: "role",
			render: (role: Role, row: Invitation, index: number) => {
				return (
					<RoleSelector
						roles={roles}
						defaultRole={role}
						loading={rowLoading == index}
						disabled={
							(rowLoading != undefined && rowLoading != index) ||
							row.user.id == user.id
						}
						onRoleChanged={(newRole) => {
							updateInvitation(
								{
									...row,
									role: { id: newRole.id, name: newRole.name },
								},
								index
							);
						}}
					/>
				);
			},
		},
		{
			width: "100px",
			title: "",
			render: (invitation: Invitation, row: Invitation, index: number) => {
				return row.user.id != user.id ? (
					<Button
						type="primary"
						danger
						icon={<DeleteOutlined />}
						loading={rowLoading == index}
						disabled={rowLoading != undefined && rowLoading != index}
						onClick={() => removeInvitation(invitation, index)}
					>
						Xóa
					</Button>
				) : null;
			},
		},
	];

	useEffect(() => {
		Promise.all([
			InvitationApi.list(1, 100, undefined, teamId),
			RoleApi.list(1, 20, teamId),
		])
			.then((vales) => {
				setInvitations(vales[0]);
				setRoles(vales[1]);
				setLoading(false);
			})
			.catch((err) => {
				message.error(err.message);
				setLoading(false);
			});
	}, [identifier.team_id]);

	return (
		<Row>
			<Col span={24} style={{ backgroundColor: "#FFF", padding: 10 }}>
				<Typography.Title level={3}>Quản lý thành viên</Typography.Title>
				<SearchUser onSelectUser={addUser} />
				<Table
					dataSource={invitations}
					columns={columns}
					rowKey={(record) => record.id}
					loading={loading}
				/>
			</Col>
		</Row>
	);
}
