import React from "react";
import NumberFormat from "react-number-format";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Row, Col, Typography, Modal, Progress, Button } from "antd";
import { SimpleInfo } from "uikit";
import { ApplicationState, Team, Invoice } from "schema/app";
import { UserApi } from "api";

import { useDispatch, useSelector } from "react-redux";
import { AddInvoice, UpdateTeam } from "app-redux/actions";

const minToWithdraw = 1000000;

export default function PaymentBalance() {
	const dispatch = useDispatch();
	const team = useSelector((state: ApplicationState) => state.team);
	const currentPercent = Math.round((team.balance / minToWithdraw) * 100);

	const onWithdraw = () => {
		Modal.confirm({
			title: "Thông báo xác nhận",
			icon: <ExclamationCircleOutlined />,
			content: "Bạn chắc chắn muốn rút tiền về tài khoản ngân hàng?",
			okText: "Có",
			cancelText: "Không",
			onOk: () => {
				UserApi.withdraw(team.balance).then((invoice: Invoice) => {
					dispatch(AddInvoice(invoice));
					if (invoice.team) {
						dispatch(UpdateTeam(invoice.team));
					}
				});
			},
		});
	};

	return (
		<div>
			<Row>
				<Col>
					<SimpleInfo title="Số dư chưa thanh toán">
						<NumberFormat
							value={team.balance}
							displayType={"text"}
							thousandSeparator={true}
							suffix=" đ"
							renderText={(value) => (
								<Typography.Title level={4}>{value}</Typography.Title>
							)}
						/>
					</SimpleInfo>
				</Col>
				<Col style={{ marginLeft: "auto" }}>
					<Button
						disabled={!(currentPercent >= 100)}
						type="primary"
						onClick={onWithdraw}
					>
						Rút tiền
					</Button>
				</Col>
			</Row>
			<Progress percent={currentPercent} showInfo={false} />
			<span>{`Bạn đã đạt ${
				currentPercent >= 100 ? 100 : currentPercent
			}% số dư tối thiểu để thanh toán`}</span>
		</div>
	);
}
