import axios from "axios";

import { HOST } from "config/api";
import { Invoice } from "schema/app";

import { store } from "app-redux";

import request from "./request";

export const INVOICE_URL = "/v1/invoices";

export const list = (page: number, limit: number) =>
	request<Array<Invoice>>("GET", INVOICE_URL, {
		page,
		limit,
	});

// export const get = (applicationId: string, teamId?: string) =>
// 	request<Application>("GET", APPLICATION_URL + "/" + applicationId);
