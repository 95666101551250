import React, { useState, useEffect } from "react";
import moment from "moment";
import {
	Table,
	Button,
	Row,
	Typography,
	Col,
	message,
	Space,
	Avatar,
} from "antd";
import { UserOutlined, EyeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import {
	ApplicationState,
	User,
	Application,
	Version,
	Team,
	ReviewSession,
} from "schema/app";
import { ApplicationApi, ReviewApi } from "api";
import { VersionStatus } from "uikit";
import { ReviewSessionView } from "../components";
import { HOST } from "config/api";

export default function AppReview() {
	const user = useSelector((state: ApplicationState) => state.user);
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const [apps, setApps] = useState<Array<Application>>([]);
	const [currentApp, setCurrentApp] = useState<Application>(undefined);

	const loadVersionForApproval = () => {
		ApplicationApi.listForApproval()
			.then((applications) => {
				setApps(applications);
			})
			.catch((err) => {
				alert(err);
			});
	};

	const handleStartSession = (application: Application) => {
		ReviewApi.createSession(application.id)
			.then((rv) => {
				handleSessionChanged(rv);
				setCurrentApp({ ...application, review_session: rv });
			})
			.catch((err) => {
				message.error(err.message);
			});
	};

	const handleSessionChanged = (rv: ReviewSession) => {
		console.log(apps);

		if (rv.status == "rejected" || rv.status == "approved") {
			setCurrentApp(undefined);
		}

		const appIndex = apps.findIndex(
			(a) =>
				(a.review_session && a.review_session.id == rv.id) ||
				a.id == rv.application.id
		);
		if (appIndex != -1) {
			const a = apps[appIndex];
			apps[appIndex] = { ...a, review_session: rv };
			setApps([...apps]);
		}
	};

	useEffect(() => {
		loadVersionForApproval();
	}, [identifier.team_id]);

	return (
		<Row gutter={10}>
			<Col span={currentApp ? 12 : 24}>
				<Typography.Title level={3}>Xét duyệt ứng dụng</Typography.Title>
				<Table
					dataSource={apps}
					columns={[
						{
							title: "Ứng dụng",
							key: "name",
							dataIndex: "name",
							width: 120,
							fixed: "left",
							sorter: {
								compare: (a: Application, b: Application) =>
									a.name.localeCompare(b.name),
								multiple: 1,
							},
							render: (value: boolean, record: Application, index: number) => {
								return (
									<Space>
										<Avatar src={`${HOST}${record.logo}`} />
										<Typography.Text>{record.name}</Typography.Text>
									</Space>
								);
							},
						},
						{
							width: 100,
							title: "Version",
							key: "review_version",
							dataIndex: "review_version",
							render: (version: Version) => {
								return <span>{version.name}</span>;
							},
						},
						{
							title: "Trạng thái",
							key: "status",
							width: 140,
							render: (app: Application) => {
								const stt =
									app.review_session?.status || app.review_version?.status;
								return <VersionStatus status={stt} />;
							},
						},
						{
							width: 120,
							title: "Cập nhật lần cuối",
							key: "updated_at",
							dataIndex: "updated_at",
							render: (updated_at: number) => {
								return <span>{moment(updated_at).fromNow()}</span>;
							},
							sorter: {
								compare: (a: Application, b: Application) =>
									b.updated_at - a.updated_at,
								multiple: 1,
							},
						},
						{
							title: "Author",
							key: "author",
							width: 100,
							dataIndex: "team",
							render: (team: Team) => {
								return <span>{team.name}</span>;
							},
						},
						{
							title: "",
							width: 140,
							key: "action",
							fixed: "right",
							render: (app: Application) => {
								const stt =
									app.review_session?.status || app.review_version?.status;
								const review_session = app.review_session;
								if (stt == "submit for review") {
									return (
										<Button
											type="primary"
											onClick={() => {
												handleStartSession(app);
											}}
										>
											Bắt đầu kiểm duyệt
										</Button>
									);
								} else if (stt == "in review" || stt == "need action") {
									if (review_session && user) {
										if (review_session.reviewer.id == user.id) {
											return (
												<Button
													type="default"
													onClick={() => {
														setCurrentApp(app);
													}}
													icon={<EyeOutlined />}
												>
													Xem phiên
												</Button>
											);
										}
										return (
											<small>
												Ứng dụng đang được kiểm duyệt
												<br />
												<UserOutlined /> {review_session.reviewer.name}
											</small>
										);
									}
								}
							},
						},
					]}
					rowKey={(record) => record.id}
					scroll={{ x: 1000 }}
				></Table>
			</Col>
			{currentApp ? (
				<Col span={12}>
					<ReviewSessionView
						app={currentApp}
						onReviewSessionChanged={handleSessionChanged}
					/>
				</Col>
			) : null}
		</Row>
	);
}
