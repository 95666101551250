import React from "react";
import { Carousel } from "antd";
import "antd/dist/antd.css";
import "./IntroSlider.css";

const IntroSlider = () => (
	<section className="slide">
		<Carousel>
			<div className="itemSlide">
				<img src={require("../images/slider-2.jpg")} alt="" />
				<div className="container">
					<div className="infoSlide">
						<div className="titleSlide">
							Hội thảo hàng năm dành cho <br /> nhà phát triển
						</div>
						<div className="btnSlide">
							<a href="/">Khám phá</a>
						</div>
					</div>
				</div>
			</div>
			<div className="itemSlide">
				<img src={require("../images/slider-3.jpg")} alt="" />
			</div>
			<div className="itemSlide">
				<img src={require("../images/slider-2.jpg")} alt="" />
			</div>
			<div className="itemSlide">
				<img src={require("../images/slider-3.jpg")} alt="" />
			</div>
		</Carousel>
	</section>
);

export default IntroSlider;
