import React, { useState, useEffect } from "react";

import moment from "moment";
import { AppPrice, AppIAP } from "schema/app";
import { useParams } from "react-router-dom";
import { Row, Col, Table, Button, Divider, Modal, Tag, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ApplicationApi } from "api";
import {
	CreatePricingModal,
	CreateAppIAPModal,
	EditPricingModal,
	EditAppIAPModal,
} from "../components/";

var numberFormat = new Intl.NumberFormat();

const EXPIRY_UNITS = {
	day: "NGÀY",
	week: "TUẦN",
	month: "THÁNG",
	quarter: "QÚY",
	year: "NĂM",
};

const RECURRENCE_UNITS = {
	daily: "NGÀY",
	weekly: "TUẦN",
	monthly: "THÁNG",
	yearly: "NĂM",
};

const priceColumns = (onEdit, onDelete) => [
	{
		title: "Bắt đầu",
		dataIndex: "start_at",
		key: "start_at",
		render: (value: number, row: any, index: number) => {
			return value
				? moment(value * 1000).format("dddd, DD/MM/YYYY HH:mm:ss")
				: "Không giới hạn";
		},
	},
	{
		title: "Kết thúc",
		dataIndex: "end_at",
		key: "end_at",
		render: (value: number, row: any, index: number) => {
			return value
				? moment(value * 1000).format("dddd, DD/MM/YYYY HH:mm:ss")
				: "Không giới hạn";
		},
	},
	{
		title: "Giá",
		dataIndex: "price",
		key: "price",
		render: (text: string, row: any, index: number) => {
			return numberFormat.format(+text);
		},
	},
	{
		title: "Tình trạng",
		dataIndex: "available",
		key: "available",
		render: (value: boolean, row: any, index: number) => {
			return (
				<Tag color={value ? "green" : "red"} key={`${value}`}>
					{value ? "MỞ" : "TẮT"}
				</Tag>
			);
		},
	},
	{
		title: "",
		dataIndex: "action",
		key: "action",
		render: (value: boolean, record: any, index: number) => {
			return (
				<Space size={10}>
					<Button
						type="primary"
						onClick={() => {
							onEdit(record, index);
						}}
					>
						Sửa
					</Button>
					<Button
						danger
						onClick={() => {
							onDelete(record, index);
						}}
					>
						Xóa
					</Button>
				</Space>
			);
		},
	},
];

const iapColumns = (onEdit, onDelete) => [
	{
		title: "Tên IAP",
		dataIndex: "name",
		key: "name",
		render: (text: string, row: any, index: number) => {
			return text;
		},
	},
	{
		title: "Item ID",
		dataIndex: "item_id",
		key: "item_id",
		render: (text: string, row: any, index: number) => {
			return (
				<Tag color="processing" key={text}>
					{text}
				</Tag>
			);
		},
	},
	{
		title: "Loại IAP",
		dataIndex: "item_type",
		key: "item_type",
		render: (text: string, row: any, index: number) => {
			return (
				<Tag color={"red"} key={text}>
					{text}
				</Tag>
			);
		},
	},
	{
		title: "Giá",
		dataIndex: "price",
		key: "price",
		render: (text: string, row: any, index: number) => {
			return numberFormat.format(+text);
		},
	},
	{
		title: "Gia hạn/Hết hạn",
		key: "other",
		dataIndex: "other",
		render: (text: string, row: any, index: number) => {
			let value = "KHÔNG";

			switch (row.item_type) {
				case "non_renewing":
					value = `${row.expiry_value} ${EXPIRY_UNITS[row.expiry_unit]}`;
					break;
				case "auto_renewable":
					value = RECURRENCE_UNITS[row.recurrence];
					break;
				default:
					value = "KHÔNG";
					break;
			}

			return (
				<Tag color={"green"} key={text}>
					{value}
				</Tag>
			);
		},
	},
	{
		title: "",
		dataIndex: "action",
		key: "action",
		render: (value: boolean, record: any, index: number) => {
			return (
				<Space size={10}>
					<Button
						type="primary"
						onClick={() => {
							onEdit(record, index);
						}}
					>
						Sửa
					</Button>
					<Button
						danger
						onClick={() => {
							onDelete(record, index);
						}}
					>
						Xóa
					</Button>
				</Space>
			);
		},
	},
];

export default function AppPricing() {
	const [displayPriceModal, setDisplayPriceModal] = useState(false);
	const [displayIapModal, setDisplayIapModal] = useState(false);
	const [selectedPrice, setSelectedPrice] = useState(null);
	const [showEditPriceModal, setShowEditPriceModal] = useState(false);
	const [selectedIAP, setSelectedIAP] = useState(null);
	const [showEditIAPModal, setShowEditIAPModal] = useState(null);

	const [appPrices, setAppPrices] = useState([]);
	const [appIAPs, setAppIAPs] = useState([]);
	const { id } = useParams();

	const loadPricings = () => {
		ApplicationApi.getAppPrices(id).then((items) => {
			setAppPrices(items);
		});
	};

	const loadIAPs = () => {
		ApplicationApi.getAppIAPs(id).then((items) => {
			setAppIAPs(items);
		});
	};

	useEffect(() => {
		loadPricings();
		loadIAPs();
	}, [id]);

	const onEditPrice = (item, index) => {
		setSelectedPrice(item);
		setShowEditPriceModal(true);
	};

	const onDeletePrice = (item, index) => {
		Modal.confirm({
			title: "Bạn có chắc muốn xóa giá này không?",
			icon: <ExclamationCircleOutlined />,
			okText: "Có",
			okType: "danger",
			cancelText: "Không",
			onOk() {
				ApplicationApi.deleteAppPrice(id, item.id)
					.then((appPrice: AppPrice) => {
						const index = appPrices.findIndex((item: AppPrice) => {
							return item.id === appPrice.id;
						});
						if (index !== -1) {
							let newAppPrices = appPrices;
							newAppPrices.splice(index, 1);
							setAppPrices(newAppPrices.slice());
						}
					})
					.catch(() => {});
			},
			onCancel() {},
		});
	};

	const onEditIAP = (item, index) => {
		setSelectedIAP(item);
		setShowEditIAPModal(true);
	};

	const onDeleteIAP = (item, index) => {
		Modal.confirm({
			title: "Bạn có chắc muốn xóa sản phẩm này không?",
			icon: <ExclamationCircleOutlined />,
			okText: "Có",
			okType: "danger",
			cancelText: "Không",
			onOk() {
				ApplicationApi.deleteAppIAP(id, item.id)
					.then((appIAP: AppIAP) => {
						const index = appIAPs.findIndex((item: AppPrice) => {
							return item.id === appIAP.id;
						});
						if (index !== -1) {
							let newAppIAPs = appIAPs;
							newAppIAPs.splice(index, 1);
							setAppIAPs(newAppIAPs.slice());
						}
					})
					.catch(() => {});
			},
			onCancel() {},
		});
	};

	return (
		<div>
			{/* <h4 className="text-uppercase title-page">Thiết lập nâng cao</h4> */}
			<Row>
				<Col span={24}>
					<h5 className="text-uppercase title-page">Thiết lập giá</h5>
					<Table
						dataSource={appPrices}
						columns={priceColumns(onEditPrice, onDeletePrice)}
					></Table>
					<Button onClick={() => setDisplayPriceModal(true)}>
						Thêm thiết lập giá
					</Button>
					<CreatePricingModal
						visible={displayPriceModal}
						onOk={(appPrice: AppPrice) => {
							setAppPrices([...appPrices, appPrice]);
							setDisplayPriceModal(false);
						}}
						onCancel={() => setDisplayPriceModal(false)}
					/>
					<EditPricingModal
						item={selectedPrice}
						visible={showEditPriceModal}
						onOk={(appPrice: AppPrice) => {
							const index = appPrices.findIndex((item: AppPrice) => {
								return item.id === appPrice.id;
							});
							if (index !== -1) {
								let newAppPrices = appPrices;
								newAppPrices[index] = appPrice;
								setAppPrices(newAppPrices.slice());
							}
							setShowEditPriceModal(false);
						}}
						onCancel={() => setShowEditPriceModal(false)}
					/>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={24}>
					<h5 className="text-uppercase title-page">In-App Purchases</h5>
					<Table
						columns={iapColumns(onEditIAP, onDeleteIAP)}
						dataSource={appIAPs}
						style={{ marginBottom: 10 }}
					></Table>
					<Button onClick={() => setDisplayIapModal(true)}>
						Tạo In-App Purchase
					</Button>
					<CreateAppIAPModal
						visible={displayIapModal}
						onOk={(appIAP: AppIAP) => {
							setAppIAPs([...appIAPs, appIAP]);
							setDisplayIapModal(false);
						}}
						onCancel={() => setDisplayIapModal(false)}
					/>
					<EditAppIAPModal
						item={selectedIAP}
						visible={showEditIAPModal}
						onOk={(appIAP: AppIAP) => {
							const index = appIAPs.findIndex((item: AppIAP) => {
								return item.id === appIAP.id;
							});
							if (index !== -1) {
								let newAppIAPs = appIAPs;
								newAppIAPs[index] = appIAP;
								console.log(newAppIAPs);
								setAppIAPs(newAppIAPs.slice());
							}
							setShowEditIAPModal(false);
						}}
						onCancel={() => setShowEditIAPModal(false)}
					/>
				</Col>
			</Row>
		</div>
	);
}
