export const USER_UPDATE = "user/Update";
export type USER_UPDATE = typeof USER_UPDATE;

export const IDENTIFIER_UPDATE = "identifier/Update";
export type IDENTIFIER_UPDATE = typeof IDENTIFIER_UPDATE;

export const TEAM_UPDATE = "team/Update";
export type TEAM_UPDATE = typeof TEAM_UPDATE;

export const TEAMS_UPDATE = "teams/Update";
export type TEAMS_UPDATE = typeof TEAMS_UPDATE;

export const INVOICES_UPDATE = "invoices/Update";
export type INVOICES_UPDATE = typeof INVOICES_UPDATE;

export const INVOICE_ADD = "invoice/ADD";
export type INVOICE_ADD = typeof INVOICE_ADD;
