import React, { useEffect, useState } from "react";
import { Layout, Dropdown, Input, Avatar, Menu, Row, Col, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

import { UserApi, InboxApi } from "api";

import "./BaseHeader.scss";
import UserHeader from "./UserHeader";
import { useSelector } from "react-redux";
import { ApplicationState } from "schema/app";

type Props = {
	children?: React.ReactNode;
};

export default function BaseHeader({ children }: Props) {
	const history = useHistory();
	const user = useSelector((state: ApplicationState) => state.user);
	const identifier = useSelector((state: ApplicationState) => state.identifier);

	const [unread, setUnread] = useState(0);

	const onSelectMenu = ({ key }) => {
		history.push("/" + key);
	};

	useEffect(() => {
		InboxApi.countUnread()
			.then((value) => {
				setUnread(value.count);
			})
			.catch((err) => {});
	}, [identifier.team_id]);

	return (
		<Layout.Header className="base-header">
			<Row>
				<Col>
					<a className="" href="#">
						<img src={require("../images/logo-white.png")} height={30} />
					</a>
				</Col>
				<Col>
					<Menu theme="dark" mode="horizontal" onSelect={onSelectMenu}>
						{user?.system_role == "Super Admin" ||
						user?.system_role == "Reviewer" ? (
							<Menu.Item key="admin">Quản trị</Menu.Item>
						) : null}
						<Menu.Item key="dashboard">Developer</Menu.Item>
					</Menu>
				</Col>
				<Col>
					<Input.Search placeholder="Tìm kiếm tài liệu " />
				</Col>
				<Col>
					{children}
					<Dropdown overlay={<UserHeader unread={unread} />}>
						<Badge count={unread}>
							<Avatar
								className="ml-4"
								size={35}
								src={user ? user.picture : null}
								icon={<UserOutlined />}
							/>
						</Badge>
					</Dropdown>
				</Col>
			</Row>
		</Layout.Header>
	);
}
