import { InboxMessage } from "schema/app";

import request from "./request";

const INBOX_URL = "/v1/messages";

export const list = () => request<Array<InboxMessage>>("GET", INBOX_URL);

export const read = (id: string) =>
	request<InboxMessage>("PUT", INBOX_URL + "/" + id, { read: true });

export const countUnread = () =>
	request<{ count: number }>("GET", INBOX_URL + "/unread");

export const create = ({ subject, content, team_id, application_id }) =>
	request<InboxMessage>("POST", INBOX_URL, {
		subject,
		content,
		team_id,
		application_id,
	});
