import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import {
	Layout,
	Row,
	Col,
	Typography,
	Table,
	Button,
	Spin,
	message,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Team, ApplicationState } from "schema/app";
import { UpdateTeam } from "app-redux/actions";

import { PaymentBalance, PaymentTransaction, PaymentInfo } from "../components";

import "./Payments.css";
import { TeamApi } from "api";

export default function Payments() {
	const disaptch = useDispatch();
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const team = useSelector((state: ApplicationState) => state.team);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		TeamApi.get(identifier.team_id)
			.then((t) => {
				disaptch(UpdateTeam(t));
				setLoading(false);
			})
			.catch((err) => {
				message.error(err.message);
				setLoading(false);
			});
	}, [identifier.team_id]);

	return (
		<Layout.Content>
			{loading ? (
				<Spin />
			) : team ? (
				<Row gutter={10}>
					<Col span={16}>
						<Row>
							<Col span={24} className="payments-section">
								<PaymentBalance />
							</Col>
						</Row>
						<Row>
							<Col span={24} className="payments-section">
								<PaymentTransaction />
							</Col>
						</Row>
					</Col>
					<Col span={8}>
						<Row>
							<Col span={24} className="payments-section">
								<PaymentInfo />
							</Col>
						</Row>
					</Col>
				</Row>
			) : (
				<p>Không thể lấy thông tin team, vui lòng thử lại!</p>
			)}
		</Layout.Content>
	);
}
