export interface User {
	id: string;
	username: string;
	cli_access_token?: string;
	name: string;
	email: string;
	phone_number: string;
	picture: string;
	system_role?: "Super Admin" | "Reviewer" | "User";
}

export interface Team {
	id: string;
	name?: string;
	workspace?: Workspace;
	role?: Role;
	workspace_id?: string;
	payment_info?: PaymentInfo;
	balance?: number;
}

export type ApplicationFunction = "authentication" | "mini_application";
export type ApplicationType = "PERSONAL" | "WORKSPACE" | "WORKSPACE_ENTERPRISE";

export interface Application {
	id: string;
	name: string;
	application_type: ApplicationType;
	api_key: string;
	secret: string;
	bundle_id: string;
	version: string;
	logo: string;
	short_description: string;
	target_workspace: string;
	description: string;
	application_function: "authentication" | "mini_application";
	screenshots: Array<string>;
	installation_package: string;
	release_package: string;
	created_at: number;
	updated_at: number;
	team?: Team;
	review_version?: Version;
	review_session?: ReviewSession;
	publish_status: "PUBLISHED" | "UNPUBLISHED";
	review_sessions?: ReviewSession[];
	metadata_change: Application;
}

export type ErrorDescription = Error & {
	errors: { [key: string]: Array<string> };
};

export interface Identifier {
	access_token?: string;
	team_id?: string;
}

export interface ApplicationState {
	user: User | null;
	identifier: Identifier | null;
	team: Team | null;
	teams: Array<Team>;
	invoices: Invoice[];
}

export interface TeamMember {
	id: string;
	username: string;
	email: string;
	name: string;
	picture: string;
}

export interface Role {
	id: string;
	name: "Developer" | "Tester" | "Team Agent" | "Super Admin" | "Reviewer";
}

export interface Invitation {
	id?: string;
	user: TeamMember;
	role: Role;
	invited_at?: number;
	updated_at?: number;
	pending?: boolean;
}

export type VersionStatusType =
	| "development"
	| "submit for review"
	| "in review"
	| "need action"
	| "approved"
	| "rejected"
	| "archived";

export interface Version {
	id: string;
	name: string;
	description: string;
	installation_package: string;
	status: VersionStatusType;
	created_at?: number;
	updated_at?: number;
	review_session?: ReviewSession;
	application?: Application;
}

export interface ReviewSession {
	id: string;
	application: Application;
	reviewer: LiteUser;
	status: VersionStatusType;
	reviews: Array<Review>;
	opened_at?: number;
	version?: Version;
}

export interface LiteUser {
	id: string;
	name: string;
	picture: string;
}

export interface LiteReviewSession {
	id: string;
	reviewer: LiteUser;
	status: string;
	opened_at: number;
}

export interface Review {
	id: string;
	content: string;
	review_type: string;
	reviewer: LiteUser;
	created_at: number;
}

export interface MQTTMessage {
	topic: string;
	message: any;
}

export interface JwtUser {
	claims: Claim;
	typ: string;
	iat: number;
	exp: number;
	iss: string;
}

export interface Claim {
	staff_id: string;
	workspace_id: string;
	token_type: string;
}

export interface Workspace {
	id: string;
	name: string;
}

export interface TeamByWorkspace {
	name: string;
	teams: Array<Team>;
}

export interface AppIAP {
	id: string;
	name: string;
	item_id: string;
	item_type: string;
	price: number;
	recurrence: string;
	expiry_value: number;
	expiry_unit: string;
	created_at: number;
	updated_at: number;
}

export interface AppPrice {
	id: string;
	price: number;
	start_at: number;
	end_at: number;
	available: boolean;
	created_at?: number;
	updated_at?: number;
}

export interface PaymentInfo {
	profile?: {
		name?: string;
		address?: string;
		tax_number?: string;
	};
	account?: {
		name?: string;
		number?: string;
		bank_name?: string;
		bank_address?: string;
	};
}

export interface Invoice {
	id: string;
	team?: Team;
	payment_info?: PaymentInfo;
	amount: number;
	linked_transaction?: {
		id: string;
		transaction_type: string;
		status: string;
		description: string;
		team?: Team;
		amount: number;
		created_at: number;
		updated_at: number;
		application: Application;
		iap_item: AppIAP;
	};
	pdf: string;
	created_at: number;
	updated_at: number;
}

export interface InboxMessage {
	id: string;
	team?: Team;
	invoice?: Invoice;
	application?: Application;
	subject: string;
	content: string;
	read: boolean;
	created_at: number;
	updated_at: number;
}

export interface TicketComment {
	id: string;
	user: User;
	content: string;
	created_at: number;
	updated_at: number;
}

export interface SupportTicket {
	id: string;
	team: Team;
	ticket_type: "general" | "application" | "invoice";
	status: "open" | "closed";
	subject: string;
	content: string;
	comments: TicketComment[];
	invoice: Invoice;
	application: Application;
	created_at: number;
	updated_at: number;
}

export const TICKET_TYPE = {
	general: {
		color: "green",
		label: "Tổng quát",
	},
	application: {
		color: "orange",
		label: "Ứng dụng",
	},
	invoice: {
		color: "red",
		label: "Hóa đơn",
	},
};

export const TICKET_STATUS = {
	open: {
		color: "green",
		label: "Mở",
	},
	closed: {
		color: "red",
		label: "Đóng",
	},
};

export interface AnalyticsDataItem {
	time: number;
	installs: number;
	uninstalls: number;
	total: number;
}

export interface AnalyticsData {
	total: Array<AnalyticsDataItem>;
	users: Array<AnalyticsDataItem>;
}
