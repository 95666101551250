import { Identifier } from "schema/app";
import { IDENTIFIER_UPDATE } from "../actions/types";
import { IdentifierUpdate } from "../actions";

const initialState: Identifier | null = null;

export default function (state = initialState, action: IdentifierUpdate) {
	switch (action.type) {
		case IDENTIFIER_UPDATE:
			return action.identifier
				? ({ ...action.identifier } as Identifier)
				: null;
		default:
			return state;
	}
}
