import React, { useEffect, useState } from "react";
import { DatePicker, Switch, Modal, InputNumber, Form, message } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";

import { ApplicationApi } from "api";
import { AppPrice } from "schema/app";

const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};

const EditPricingModal = ({ item, visible, onOk, onCancel }) => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const { id } = useParams();

	useEffect(() => {
		form.setFieldsValue({
			...item,
			start_at: (item && item.start_at && moment(item.start_at * 1000)) || null,
			end_at: (item && item.end_at && moment(item.end_at * 1000)) || null,
		});
	}, [item]);

	return (
		<Modal
			title="Sửa thiết lập giá"
			centered
			destroyOnClose
			confirmLoading={loading}
			visible={visible}
			onOk={() => {
				setLoading(true);
				form
					.validateFields()
					.then((values) => {
						ApplicationApi.updateAppPrice(
							id,
							item.id,
							values.price,
							values.start_at && values.start_at.unix(),
							values.end_at && values.end_at.unix(),
							values.available
						)
							.then((responseItem: AppPrice) => {
								if (responseItem.id) {
									onOk(responseItem);
									form.resetFields();
								} else {
									message.error("Lỗi! Không thể cập nhật giá cho ứng dụng này");
								}
								setLoading(false);
							})
							.catch(() => {
								setLoading(false);
							});
					})
					.catch((info) => {
						setLoading(false);
						console.log("Validate Failed:", info);
					});
			}}
			onCancel={onCancel}
		>
			<Form
				form={form}
				initialValues={{
					...item,
					start_at: (item && moment(item.start_at * 1000)) || null,
					end_at: (item && moment(item.end_at * 1000)) || null,
				}}
			>
				<Form.Item {...formItemLayout} name="price" label="Giá">
					<InputNumber
						step={100}
						formatter={(value) =>
							`đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => (value as string).replace(/\đ\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						contentEditable="false"
						min={0}
					/>
				</Form.Item>
				<Form.Item {...formItemLayout} name="start_at" label="Bắt đầu">
					<DatePicker
						showTime
						style={{ width: "100%" }}
						placeholder="Không giới hạn"
						onChange={(value) => {}}
					/>
				</Form.Item>
				<Form.Item {...formItemLayout} name="end_at" label="Kết thúc">
					<DatePicker
						showTime
						style={{ width: "100%" }}
						placeholder="Không giới hạn"
						onChange={(value) => {}}
					/>
				</Form.Item>
				<Form.Item
					{...formItemLayout}
					name="available"
					label="Kích hoạt"
					valuePropName="checked"
				>
					<Switch />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditPricingModal;
