import React, { useEffect, useState } from "react";
import { Chart } from "react-charts";
import {
	Layout,
	Typography,
	DatePicker,
	message,
	Space,
	Select,
	Table,
	Switch,
} from "antd";
import { useSelector } from "react-redux";
import moment from "moment";

import "./Analytics.css";
import { AnalyticsApi, ApplicationApi } from "api";
import { ApplicationState, Application } from "schema/app";

type ChartDataType = {
	label: string;
	data: Array<Array<number>>;
	color?: string;
};

export default function Analytics() {
	const currentTeam = useSelector((state: ApplicationState) => state.team);
	const identifier = useSelector((state: ApplicationState) => state.identifier);

	const [dateRange, setDateRange] = useState({
		start: moment().subtract(7, "day"),
		end: moment(),
	});
	const [data, setData] = useState<Array<ChartDataType>>(undefined);
	const [tableData, setTableData] = useState<Array<Array<number>>>(undefined);
	const [apps, setApps] = useState<Array<Application>>([]);
	const [selectedAppId, setSelectedAppId] = useState("0");
	const [showTotal, setShowTotal] = useState(true);

	useEffect(() => {
		if (identifier?.team_id == null) return;
		ApplicationApi.list(1, 100, identifier?.team_id)
			.then((applications) => {
				setApps(applications);
			})
			.catch((err) => {
				message.error(err.message);
			});
	}, [identifier]);

	useEffect(() => {
		const getDaily = AnalyticsApi.getDaily(
			dateRange.start.toDate().getTime(),
			dateRange.end.toDate().getTime(),
			selectedAppId != "0" ? selectedAppId : undefined
		);
		const getTotal = AnalyticsApi.getTotalDaily(
			dateRange.start.toDate().getTime(),
			dateRange.end.toDate().getTime(),
			selectedAppId != "0" ? selectedAppId : undefined
		);

		Promise.all([getDaily, getTotal])
			.then((objs) => {
				console.log(objs);
				const dailyData = objs[0].total;
				const totalData = objs[1].total;
				const label =
					selectedAppId == "0"
						? currentTeam.name
						: apps.find((a) => a.id == selectedAppId)?.name;
				const chartTotalData = totalData.map((item) => {
					return [item.time, item.installs];
				});
				const chartDailyData = dailyData.map((item) => {
					return [item.time, item.installs];
				});
				const allData = dailyData.map((item, index) => {
					return [item.time, item.installs, totalData[index].installs];
				});

				if (showTotal) {
					setData([
						{
							label: `${label} - Total`,
							data: chartTotalData,
							color: "#e74c3c",
						},
						{
							label: `${label} - Daily`,
							data: chartDailyData,
							color: "#2980b9",
						},
					]);
				} else {
					setData([
						{
							label: `${label} - Daily`,
							data: chartDailyData,
							color: "#2980b9",
						},
					]);
				}
				setTableData(allData);
			})
			.catch((err) => {
				message.error(err.message);
			});
	}, [dateRange, selectedAppId, showTotal]);

	const series = React.useMemo(
		() => ({
			showPoints: true,
		}),
		[]
	);

	const axes = [
		{ primary: true, type: "time", position: "bottom" },
		{ type: "linear", position: "left" },
	];

	const columns = [
		{
			title: "Thời gian",
			render: (item: number[]) => {
				return moment(item[0]).format("DD/MM/YYYY");
			},
		},
		{
			title: "Số lượt mua hàng ngày",
			render: (item: number[]) => {
				return item[1];
			},
		},
		{
			title: "Tổng Số lượt mua",
			render: (item: number[]) => {
				return item[2];
			},
		},
	];

	function onChange(dates, dateStrings) {
		console.log("From: ", dates[0], ", to: ", dates[1]);
		console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
		setDateRange({ start: dates[0], end: dates[1] });
	}

	console.log(data);

	return (
		<div className="analytics-wrapper">
			<Typography.Title level={3}>Thống kê người dùng</Typography.Title>
			<Space className="analytics-tools">
				<DatePicker.RangePicker
					value={[dateRange.start, dateRange.end]}
					ranges={{
						"7 ngày vừa qua": [moment().subtract(7, "day"), moment()],
						"30 ngày vừa qua": [moment().subtract(30, "day"), moment()],
						"90 ngày vừa qua": [moment().subtract(90, "day"), moment()],
						"6 tháng": [
							moment().subtract(6, "month").startOf("month"),
							moment(),
						],
						"Năm nay": [moment().startOf("year"), moment().endOf("year")],
					}}
					onChange={onChange}
				/>
				<Select
					className="analytics-filter-app"
					value={selectedAppId}
					onChange={(e) => {
						setSelectedAppId(e);
					}}
				>
					<Select.Option value="0">Tất cả</Select.Option>
					{apps.map((a) => (
						<Select.Option value={a.id}>{a.name}</Select.Option>
					))}
				</Select>
				<Switch
					checkedChildren="Hiển thị tổng số"
					unCheckedChildren="Không hiện tổng số"
					checked={showTotal}
					onChange={(checked) => {
						setShowTotal(checked);
					}}
				/>
			</Space>
			{data ? (
				<div className="analytics-chart">
					<Chart data={data} axes={axes} series={series} tooltip />
				</div>
			) : null}
			{tableData ? (
				<Table
					dataSource={tableData}
					columns={columns}
					rowKey={(record) => record[0]}
				/>
			) : null}
		</div>
	);
}
