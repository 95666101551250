import axios from "axios";

import { HOST } from "config/api";
import {
	User,
	ErrorDescription,
	Application,
	Team,
	AppIAP,
	AppPrice,
} from "schema/app";

import { store } from "app-redux";
import { UserApi } from "api";

import request from "./request";

export const APPLICATION_URL = "/v1/applications";

export const list = (page: number, limit: number, teamId?: string) =>
	request<Array<Application>>("GET", APPLICATION_URL, {
		page,
		limit,
		dashboard: "true",
	});

export const get = (applicationId: string, teamId?: string) =>
	request<Application>("GET", APPLICATION_URL + "/" + applicationId);

export const listForApproval = (page: number = 1, limit: number = 20) =>
	request<Array<Application>>("GET", APPLICATION_URL, {
		page,
		limit,
		submitted: "true",
	});

export const listForSearch = (
	keyword?: string,
	page?: number,
	limit?: number
) =>
	request<Array<Application>>("GET", APPLICATION_URL, {
		page: page || 1,
		limit: limit || 20,
		q: keyword,
		search: "true",
	});

export const createOrUpdate = (
	app: { [key in keyof Application]?: any },
	teamId: string
) =>
	request<Application>(
		app.id ? "PUT" : "POST",
		app.id ? APPLICATION_URL + "/" + app.id : APPLICATION_URL,
		app
	);

export const getAppPrices = (appId) => {
	return request<AppPrice[]>(
		"GET",
		`${APPLICATION_URL}/${appId}/price_schedules`
	);
};

export const createAppPrice = (appId, price, start_at, end_at, available) => {
	return request<AppPrice>(
		"POST",
		`${APPLICATION_URL}/${appId}/price_schedules`,
		{
			price,
			start_at,
			end_at,
			available,
		}
	);
};

export const updateAppPrice = (
	appId,
	priceId,
	price,
	start_at,
	end_at,
	available
) => {
	return request<AppPrice>(
		"PUT",
		`${APPLICATION_URL}/${appId}/price_schedules/${priceId}`,
		{
			price,
			start_at,
			end_at,
			available,
		}
	);
};

export const deleteAppPrice = (appId, priceId) => {
	return request<AppPrice>(
		"DELETE",
		`${APPLICATION_URL}/${appId}/price_schedules/${priceId}`
	);
};

export const getAppIAPs = (appId) => {
	return request<AppIAP[]>("GET", `${APPLICATION_URL}/${appId}/iap_items`);
};

export const createAppIAP = (
	appId,
	item_id,
	price,
	name,
	item_type,
	recurrence,
	expiry_value,
	expiry_unit
) => {
	return request<AppIAP>("POST", `${APPLICATION_URL}/${appId}/iap_items`, {
		item_id,
		price,
		name,
		item_type,
		recurrence,
		expiry_value,
		expiry_unit,
	});
};

export const updateAppIAP = (
	appId,
	iapId,
	item_id,
	price,
	name,
	item_type,
	recurrence,
	expiry_value,
	expiry_unit
) => {
	return request<AppIAP>(
		"PUT",
		`${APPLICATION_URL}/${appId}/iap_items/${iapId}`,
		{
			item_id,
			price,
			name,
			item_type,
			recurrence,
			expiry_value,
			expiry_unit,
		}
	);
};

export const deleteAppIAP = (appId, iapId) => {
	return request<AppIAP>(
		"DELETE",
		`${APPLICATION_URL}/${appId}/iap_items/${iapId}`
	);
};

export const archive = (appId, versionId) => {
	return request<Application>(
		"POST",
		`${APPLICATION_URL}/${appId}/versions/${versionId}/archive`
	);
};
