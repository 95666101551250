import React from "react";
import "antd/dist/antd.css";
import "./PublicFooter.css";

const PublicFooter = () => (
	<>
		<footer className="footer3">
			<div className="container">
				<div className=" main-footer">
					<div className="footer-sidebar widget-area">
						<div className="shopify-section row">
							<div className="footer-column footer-column-2 col-md-3 col-sm-6">
								<div
									id="text-1515353481481"
									className="woodmart-widget footer-widget widget_text"
								>
									<h5 className="widget-title">Sản phẩm</h5>
									<div className="textwidget">
										<ul className="menu">
											<li>
												<a href="/">Trí tuệ nhân tạo</a>
											</li>
											<li>
												<a href="/">Công cụ kinh doanh</a>
											</li>
											<li>
												<a href="/">Nền tảng chơi game</a>
											</li>
											<li>
												<a href="/">Chính sách bảo hành</a>
											</li>
											<li>
												<a href="/">Tích hợp xã hội</a>
											</li>
											<li>
												<a href="/">Khuyến mãi &amp; Mã giảm giá</a>
											</li>
											<li>
												<a href="/">Biểu phí lắp đặt, bảo trì</a>
											</li>
											<li>
												<a href="/">Hướng dẫn đặt hàng</a>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="footer-column footer-column-3 col-md-3 col-sm-6 column-uppercase">
								<div className="woodmart-widget footer-widget widget_text">
									<h5 className="widget-title">THÔNG TIN CẦN BIẾT</h5>
									<ul className="menu">
										<li>Hotline: 1900 3033 </li>
										<li>
											<a href="/">Liên hệ</a>
										</li>
										<li>
											<a href="/">Quy chế hoạt động</a>
										</li>
										<li>
											<a href="/">Điều kiện giao dịch chung</a>
										</li>
										<li>
											<a href="/">Chính sách bảo mật</a>
										</li>
									</ul>
									<br />
								</div>
							</div>
							<div className="footer-column footer-column-4 col-md-3 col-sm-6">
								<div
									id="text-1550456220706"
									className="woodmart-widget footer-widget widget_text"
								>
									<h5 className="widget-title">Phương thức thanh toán</h5>

									<p>
										<img
											src={require("../images/payment_logo.png")}
											alt="Shopify secure badge"
											width={210}
										/>
									</p>
								</div>
							</div>
							<div className="footer-column footer-column-4 col-md-3 col-sm-6">
								<div
									id="text-1550456220706"
									className="woodmart-widget footer-widget widget_text"
								>
									<h5 className="widget-title">KẾT NỐI VỚI CHÚNG TÔI</h5>

									<nav className="social">
										<ul className="list-inline">
											<li className="list-inline-item">
												<a href="/" rel="nofollow">
													<svg
														width="29"
														height="29"
														viewBox="0 0 29 29"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M8.41767 0C3.78185 0 0 3.78185 0 8.41767V20.591C0 25.2181 3.78185 29 8.41767 29H20.591C25.2181 29 29 25.2182 29 20.5823V8.41767C29 3.78185 25.2182 0 20.5823 0H8.41767ZM8.41767 2.23077H20.5823C24.0156 2.23077 26.7692 4.98438 26.7692 8.41767V20.5823C26.7692 24.0156 24.0156 26.7692 20.591 26.7692H8.41767C4.98438 26.7692 2.23077 24.0156 2.23077 20.591V8.41767C2.23077 4.98438 4.98438 2.23077 8.41767 2.23077ZM22.3077 5.57692C22.3077 4.95823 22.8044 4.46154 23.4231 4.46154C24.0418 4.46154 24.5385 4.95823 24.5385 5.57692C24.5385 6.19561 24.0418 6.69231 23.4231 6.69231C22.8044 6.69231 22.3077 6.19561 22.3077 5.57692ZM14.5 6.69231C10.204 6.69231 6.69231 10.204 6.69231 14.5C6.69231 18.796 10.204 22.3077 14.5 22.3077C18.796 22.3077 22.3077 18.796 22.3077 14.5C22.3077 10.204 18.796 6.69231 14.5 6.69231ZM20.0769 14.5C20.0769 11.4065 17.5934 8.92308 14.5 8.92308C11.4065 8.92308 8.92308 11.4065 8.92308 14.5C8.92308 17.5934 11.4065 20.0769 14.5 20.0769C17.5934 20.0769 20.0769 17.5934 20.0769 14.5Z"
															fill="black"
														/>
													</svg>
												</a>
											</li>
											<li className="list-inline-item">
												<a
													href="https://www.youtube.com/channel/UCTOs_SDJuy2ENBY2KwEoiAw"
													rel="nofollow"
												>
													<svg
														width="31"
														height="23"
														viewBox="0 0 31 23"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M26.8981 0.861939C28.4827 1.22131 29.8481 2.37244 30.1347 3.95312C30.4943 5.75 30.8539 8.26562 31 11.5C30.927 14.6614 30.5673 17.25 30.2808 19.0469C29.9914 20.7006 28.626 21.7787 27.0442 22.1381C24.5269 22.6406 20.2115 23 15.4635 23C10.7154 23 6.40004 22.6406 3.88273 22.1381C2.30098 21.7787 0.935563 20.6276 0.646185 19.0469C0.286569 17.25 0 14.7344 0 11.5C0 8.26562 0.286569 5.75 0.575947 3.95312C0.862516 2.29944 2.23074 1.22131 3.81249 0.861939C6.18651 0.359375 10.6452 0 15.3904 0C20.1385 0 24.5269 0.359375 26.8981 0.861939ZM20.2818 11.5L11.5077 6.46875V16.5312L20.2818 11.5Z"
															fill="black"
														/>
													</svg>
												</a>
											</li>
											<li className="list-inline-item">
												<a
													href="https://www.facebook.com/smart.appotahome/"
													rel="nofollow"
												>
													<svg
														width="29"
														height="29"
														viewBox="0 0 29 29"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M5.8 0H23.2C26.3991 0 29 2.60094 29 5.8V23.2C29 26.3991 26.3991 29 23.2 29H5.8C2.60094 29 0 26.3991 0 23.2V5.8C0 2.60094 2.60094 0 5.8 0ZM20.7078 10.052H22.475V7.11284C22.475 7.11284 20.6503 6.525 18.9406 6.525C15.7491 6.525 14.8172 8.4791 14.8172 11.2277V12.9912H13.05V16.5182H14.8172V28.275H18.9406V16.5182H21.8859L22.475 12.9912H18.9406V11.2277C18.9406 10.3482 19.4445 10.052 20.7078 10.052Z"
															fill="black"
														/>
													</svg>
												</a>
											</li>
										</ul>
									</nav>

									<h5 className="widget-title mt-3 mb-3">
										TẢI ỨNG DỤNG TRÊN ĐIỆN THOẠI
									</h5>
									<div className="download">
										<div>
											<div className="ios">
												<a
													href="https://itunes.apple.com/vn/app/acheckin/id1451269978?mt=8"
													rel="nofollow"
												>
													<img
														src={require("../images/dl-ios.png")}
														alt="Download Ios"
														width={157}
													/>
												</a>
											</div>
											<div className="android">
												<a
													href="https://play.google.com/store/apps/details?id=vn.acheckin"
													rel="nofollow"
												>
													<img
														src={require("../images/dl-android.png")}
														alt="Download android"
														width={157}
													/>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>

		<footer>
			<div className="innerfooter">
				<nav className="infos">
					<div className="container">
						<p>
							Công ty chủ quản: Công ty Cổ phần AppotaPay. Trụ sở: số 11, ngõ
							71, phố Láng Hạ, Phường Thành Công, Quận Ba Đình, Thành phố Hà
							Nội, Việt Nam. Số giấy chứng nhận: 0107150713
						</p>
						<p>
							<strong
								style={{ fontSize: "13px", verticalAlign: "text-bottom" }}
							>
								HÀ NỘI:
							</strong>{" "}
							Tòa nhà Appota, số 11, ngõ 71 Láng Hạ, Ba Đình, Hà Nội, Việt Nam.
							|
							<b style={{ fontSize: "13px", verticalAlign: "text-bottom" }}>
								{" "}
								HỒ CHÍ MINH:
							</b>{" "}
							Tầng 10,198 Đường 3/2 P12 Q10, TP.Hồ Chí Minh, Việt Nam
						</p>
						<p>Hotline: 1900 3033 | Email: sale@acheckin.vn</p>
					</div>
				</nav>
				<nav className="social">
					<div className="container">
						<ul className="list-inline">
							<li className="list-inline-item">
								<a href="/" rel="nofollow">
									<svg
										width="29"
										height="29"
										viewBox="0 0 29 29"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8.41767 0C3.78185 0 0 3.78185 0 8.41767V20.591C0 25.2181 3.78185 29 8.41767 29H20.591C25.2181 29 29 25.2182 29 20.5823V8.41767C29 3.78185 25.2182 0 20.5823 0H8.41767ZM8.41767 2.23077H20.5823C24.0156 2.23077 26.7692 4.98438 26.7692 8.41767V20.5823C26.7692 24.0156 24.0156 26.7692 20.591 26.7692H8.41767C4.98438 26.7692 2.23077 24.0156 2.23077 20.591V8.41767C2.23077 4.98438 4.98438 2.23077 8.41767 2.23077ZM22.3077 5.57692C22.3077 4.95823 22.8044 4.46154 23.4231 4.46154C24.0418 4.46154 24.5385 4.95823 24.5385 5.57692C24.5385 6.19561 24.0418 6.69231 23.4231 6.69231C22.8044 6.69231 22.3077 6.19561 22.3077 5.57692ZM14.5 6.69231C10.204 6.69231 6.69231 10.204 6.69231 14.5C6.69231 18.796 10.204 22.3077 14.5 22.3077C18.796 22.3077 22.3077 18.796 22.3077 14.5C22.3077 10.204 18.796 6.69231 14.5 6.69231ZM20.0769 14.5C20.0769 11.4065 17.5934 8.92308 14.5 8.92308C11.4065 8.92308 8.92308 11.4065 8.92308 14.5C8.92308 17.5934 11.4065 20.0769 14.5 20.0769C17.5934 20.0769 20.0769 17.5934 20.0769 14.5Z"
											fill="black"
										/>
									</svg>
								</a>
							</li>
							<li className="list-inline-item">
								<a href="/" rel="nofollow">
									<svg
										width="31"
										height="23"
										viewBox="0 0 31 23"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M26.8981 0.861939C28.4827 1.22131 29.8481 2.37244 30.1347 3.95312C30.4943 5.75 30.8539 8.26562 31 11.5C30.927 14.6614 30.5673 17.25 30.2808 19.0469C29.9914 20.7006 28.626 21.7787 27.0442 22.1381C24.5269 22.6406 20.2115 23 15.4635 23C10.7154 23 6.40004 22.6406 3.88273 22.1381C2.30098 21.7787 0.935563 20.6276 0.646185 19.0469C0.286569 17.25 0 14.7344 0 11.5C0 8.26562 0.286569 5.75 0.575947 3.95312C0.862516 2.29944 2.23074 1.22131 3.81249 0.861939C6.18651 0.359375 10.6452 0 15.3904 0C20.1385 0 24.5269 0.359375 26.8981 0.861939ZM20.2818 11.5L11.5077 6.46875V16.5312L20.2818 11.5Z"
											fill="black"
										/>
									</svg>
								</a>
							</li>
							<li className="list-inline-item">
								<a href="/" rel="nofollow">
									<svg
										width="29"
										height="29"
										viewBox="0 0 29 29"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M5.8 0H23.2C26.3991 0 29 2.60094 29 5.8V23.2C29 26.3991 26.3991 29 23.2 29H5.8C2.60094 29 0 26.3991 0 23.2V5.8C0 2.60094 2.60094 0 5.8 0ZM20.7078 10.052H22.475V7.11284C22.475 7.11284 20.6503 6.525 18.9406 6.525C15.7491 6.525 14.8172 8.4791 14.8172 11.2277V12.9912H13.05V16.5182H14.8172V28.275H18.9406V16.5182H21.8859L22.475 12.9912H18.9406V11.2277C18.9406 10.3482 19.4445 10.052 20.7078 10.052Z"
											fill="black"
										/>
									</svg>
								</a>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</footer>
	</>
);

export default PublicFooter;
