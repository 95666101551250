import React, { useEffect, useState } from "react";
import {
	Input,
	Select,
	Typography,
	Modal,
	InputNumber,
	Form,
	Row,
	Col,
	message,
} from "antd";
import { useParams } from "react-router-dom";

import { ApplicationApi } from "api";
import { AppIAP } from "schema/app";

const { Option } = Select;
const { Text } = Typography;
const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};

const EditAppIAPModal = ({ item, visible, onOk, onCancel }) => {
	const [loading, setLoading] = useState(false);
	const [itemType, setItemType] = useState("consumable");
	const [expiryValue, setExpiryValue] = useState(null);
	const [expiryUnit, setExpiryUnit] = useState(null);
	const [form] = Form.useForm();
	const { id } = useParams();

	const onExpiryValueChange = (value) => {
		setExpiryValue(value);
	};

	const onExpiryUnitChange = (value) => {
		setExpiryUnit(value);
	};

	useEffect(() => {
		if (item) {
			form.setFieldsValue(item);
			setItemType(item.item_type);
			setExpiryValue(item.expiry_value);
			setExpiryUnit(item.expiry_unit);
		}
	}, [item]);

	return (
		<Modal
			title="Tạo In-App Purchase"
			centered
			destroyOnClose
			confirmLoading={loading}
			visible={visible}
			onOk={() => {
				setLoading(true);
				form
					.validateFields()
					.then((values) => {
						ApplicationApi.updateAppIAP(
							id,
							item.id,
							values.item_id,
							values.price,
							values.name,
							values.item_type,
							values.item_type !== "auto_renewable" ? null : values.recurrence,
							expiryValue,
							expiryUnit
						)
							.then((responseItem: AppIAP) => {
								if (responseItem.id) {
									onOk(responseItem);
									form.resetFields();
								} else {
									message.error("Lỗi! Không thể cập nhật sản phẩm này");
								}
								setLoading(false);
							})
							.catch(() => {
								setLoading(false);
							});
					})
					.catch((info) => {
						console.log("Validate Failed:", info);
						setLoading(false);
					});
			}}
			onCancel={onCancel}
		>
			<Form
				form={form}
				initialValues={{
					price: 0,
					name: "",
					item_id: "",
					item_type: "consumable",
					recurrence: "weekly",
				}}
			>
				<Form.Item {...formItemLayout} name="name" label="Tên IAP">
					<Input type="text"></Input>
				</Form.Item>
				<Form.Item {...formItemLayout} name="item_id" label="Item ID">
					<Input type="text" placeholder="com.example.product_id"></Input>
				</Form.Item>
				<Form.Item {...formItemLayout} name="item_type" label="Loại IAP">
					<Select
						optionLabelProp="title"
						onChange={(value) => {
							setItemType(value.toString());
						}}
					>
						<Option key="consumable" title="Consumable" value="consumable">
							<b>Consumable</b>
							<br />
							<Text type="secondary">
								A product that is used once, after which it becomes depleted and
								must be purchased again
							</Text>
						</Option>
						<Option
							key="non_consumable"
							title="Non-Consumable"
							value="non_consumable"
						>
							<b>Non-Consumable</b>
							<br />
							<Text type="secondary">
								A product that is purchased once and does not expire or decrease
								with use.
							</Text>
						</Option>
						<Option
							key="auto_renewable"
							title="Auto-Renewable Subscription"
							value="auto_renewable"
						>
							<b>Auto-Renewable Subscription</b>
							<br />
							<Text type="secondary">
								A product that allows users to purchase dynamic content for a
								set period. This type of Subscription renews automatically
								unless cancelled by the user.
							</Text>
						</Option>
						<Option
							key="non_renewing"
							title="Non-Renewing Subscription"
							value="non_renewing"
						>
							<b>Non-Renewing Subscription</b>
							<br />
							<Text type="secondary">
								A product that allows users to purchase a service with a limited
								duration. The content of this in-app purchase can be static.
								This type of Subscription does not renew automatically.
							</Text>
						</Option>
					</Select>
				</Form.Item>
				<Form.Item {...formItemLayout} name="price" label="Giá">
					<InputNumber
						step={100}
						formatter={(value) =>
							`đ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => (value as string).replace(/\đ\s?|(,*)/g, "")}
						style={{ width: "100%" }}
						contentEditable="false"
						min={0}
					/>
				</Form.Item>
				{itemType === "auto_renewable" ? (
					<Form.Item {...formItemLayout} name="recurrence" label="Chu kỳ">
						<Select>
							<Option value="weekly" key="weekly">
								Tuần
							</Option>
							<Option value="monthly" key="monthly">
								Tháng
							</Option>
							<Option value="yearly" key="yearly">
								Năm
							</Option>
						</Select>
					</Form.Item>
				) : null}
				{itemType === "non_renewing" ? (
					<Form.Item {...formItemLayout} label="Hết hạn">
						<Row>
							<Col span="16">
								<InputNumber
									step={1}
									formatter={(value) =>
										`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									}
									parser={(value) =>
										(value as string).replace(/\đ\s?|(,*)/g, "")
									}
									style={{ width: "100%" }}
									contentEditable="false"
									min={0}
									defaultValue={expiryValue}
									onChange={onExpiryValueChange}
								/>
							</Col>
							<Col span="8">
								<Select defaultValue={expiryUnit} onChange={onExpiryUnitChange}>
									<Option value="day" key="dau">
										Ngày
									</Option>
									<Option value="week" key="week">
										Tuần
									</Option>
									<Option value="month" key="month">
										Tháng
									</Option>
									<Option value="quarter" key="quarter">
										Quý
									</Option>
									<Option value="yearly" key="yearly">
										Năm
									</Option>
								</Select>
							</Col>
						</Row>
					</Form.Item>
				) : null}
			</Form>
		</Modal>
	);
};

export default EditAppIAPModal;
