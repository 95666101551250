import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import {
	Collapse,
	Layout,
	Comment,
	Avatar,
	Tooltip,
	Row,
	Col,
	Form,
	Input,
	Button,
	message,
} from "antd";
import { useSelector } from "react-redux";

import { ReviewApi } from "api";
import { Version, ApplicationState, ReviewSession } from "schema/app";

type Props = {
	version: Version;
	onReviewSessionChanged: (s: ReviewSession) => void;
};

export default function ReviewSessionView({
	version,
	onReviewSessionChanged,
}: Props) {
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const [loading, setLoading] = useState(false);
	const bottomDiv = useRef(undefined);
	const [form] = Form.useForm();

	const onFinish = (values) => {
		setLoading(true);
		const { comment } = values;
		ReviewApi.updateSession(version.review_session.id, version.status, comment)
			.then((session) => {
				setLoading(false);
				onReviewSessionChanged(session);
			})
			.catch((err) => {
				setLoading(false);
				message.error(err.message);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const scrollToBottom = () => {
		bottomDiv.current.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(() => {
		scrollToBottom();
		form.setFieldsValue({ comment: "" });
	}, [version.review_session]);

	return (
		<Row style={{ marginBottom: 10 }}>
			<Col span={24}>
				<Collapse defaultActiveKey="review">
					<Collapse.Panel header="Lời nhắn từ đội ngũ kiểm duyệt" key="review">
						<div style={{ maxHeight: 200, overflow: "auto" }}>
							{version.review_session.reviews
								.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
								.map((r) => {
									return (
										<Comment
											key={r.id}
											author={<a>{r.reviewer?.name ?? "ACheckin Team"}</a>}
											avatar={
												<Avatar>
													{r.reviewer?.name.charAt(0).toUpperCase() ?? "A"}
												</Avatar>
											}
											content={<p>{r.content}</p>}
											datetime={
												<Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
													<span>{moment(r.created_at).fromNow()}</span>
												</Tooltip>
											}
										/>
									);
								})}

							<div ref={bottomDiv} />
						</div>
						{version.status == "rejected" ? null : (
							<Form
								form={form}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
							>
								<Form.Item
									name="comment"
									rules={[
										{
											required: true,
											message: "Vui lòng nhập lời nhắn tối thiểu 10 kí tự!",
											min: 10,
										},
									]}
								>
									<Input.TextArea />
								</Form.Item>
								<Form.Item>
									<Button type="primary" htmlType="submit" loading={loading}>
										Gửi
									</Button>
								</Form.Item>
							</Form>
						)}
					</Collapse.Panel>
				</Collapse>
			</Col>
		</Row>
	);
}
