import React, { useEffect, useState } from "react";
import {
	Input,
	Select,
	Typography,
	Modal,
	InputNumber,
	Form,
	message,
} from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateTeam, UpdateTeams } from "app-redux/actions";

import { TeamApi } from "api";
import { ApplicationState, Team } from "schema/app";

const { Option } = Select;
const { Text } = Typography;
const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};

const EditTeamInfoModal = ({ item, visible, onOk, onCancel }) => {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const teams = useSelector((state: ApplicationState) => state.teams);
	const dispatch = useDispatch();

	useEffect(() => {
		form.setFieldsValue({
			name: item.name,
		});
	}, [item]);

	const updateTeams = (team: Team) => {
		let newTeams = teams;
		const index = teams.findIndex((element) => {
			return element.id === item.id;
		});

		if (index !== -1) {
			newTeams[index] = team;
			dispatch(UpdateTeams(newTeams));
		}
	};

	return (
		<Modal
			title="Thay đổi thông tin team"
			centered
			destroyOnClose
			confirmLoading={loading}
			visible={visible}
			onOk={() => {
				setLoading(true);
				form
					.validateFields()
					.then((values) => {
						TeamApi.update({ ...item, name: values.name })
							.then((team) => {
								if (team.id) {
									form.resetFields();
									dispatch(UpdateTeam(team));
									updateTeams(team);
									onOk();
								} else {
									message.error("Lỗi! Không thể cập nhật thông tin");
								}
								setLoading(false);
							})
							.catch(() => {
								setLoading(false);
							});
					})
					.catch((info) => {
						console.log("Validate Failed:", info);
						setLoading(false);
					});
			}}
			onCancel={onCancel}
		>
			<Form
				form={form}
				initialValues={{
					name: "",
				}}
			>
				<Form.Item {...formItemLayout} name="name" label="Tên">
					<Input type="text"></Input>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditTeamInfoModal;
