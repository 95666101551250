import { User } from "schema/app";
import { USER_UPDATE } from "../actions/types";
import { UserUpdate } from "../actions";

const initialState: User | null = null;

export default function (state = initialState, action: UserUpdate) {
	switch (action.type) {
		case USER_UPDATE:
			return action.user ? ({ ...action.user } as User) : null;
		default:
			return state;
	}
}
