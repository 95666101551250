import React, { Component } from "react";
import "antd/dist/antd.css";
import "./ReleaseManagement.css";
import { DownloadOutlined } from "@ant-design/icons";
import { Empty, Button, Layout, Collapse } from "antd";

import { ApplicationState, User, Version } from "schema/app";
import { Dispatch } from "redux";
import { UpdateUser } from "app-redux/actions";
import { connect, ConnectedProps } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { VersionApi } from "api";
import { HOST } from "config/api";
import { DevelopmentSection, ReleasedSection } from "../components";

export default function ReleaseManagement() {
	return (
		<Layout.Content>
			<Collapse defaultActiveKey={["published", "development"]}>
				<Collapse.Panel header="Trực tuyến" key="published">
					<ReleasedSection />
				</Collapse.Panel>
				<Collapse.Panel header="Đang phát triển" key="development">
					<DevelopmentSection />
				</Collapse.Panel>
			</Collapse>
		</Layout.Content>
	);
}
