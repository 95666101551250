import axios from "axios";

import { HOST } from "config/api";
import { Version, ErrorDescription, User } from "schema/app";

import { store } from "app-redux";
import request from "./request";

export const VERSIONS_URL = `/v1/applications/:application_id/versions`;
const DEVELOPMENT_VERSION_URL = `/v1/applications/:application_id/versions/development`;
const RELEASE_VERSION_URL = `/v1/applications/:application_id/versions/release`;

const get = (
	applicationId: string,
	teamId: string,
	type: "develop" | "released"
) => {
	const accessToken = store.getState().identifier?.access_token;
	const headers: { [key: string]: string } = {
		"X-Access-Token": accessToken,
		"X-Team-Id": teamId,
	};

	const url = type == "develop" ? DEVELOPMENT_VERSION_URL : RELEASE_VERSION_URL;
	return axios
		.get(HOST + url.replace(":application_id", applicationId), {
			headers,
		})
		.then((response) => {
			if (response && response.data) {
				return response.data as Version;
			}
			throw new Error("Type error");
		});
};

export const development = (applicationId: string, teamId: string) =>
	get(applicationId, teamId, "develop");

export const release = (application_id: string, teamId: string) =>
	get(application_id, teamId, "released");

export const createOrUpdate = (
	version: { [key in keyof Version]?: any },
	app_id: string,
	teamId?: string
) => {
	const accessToken = store.getState().identifier?.access_token;
	let headers: { [key: string]: string } = {
		"X-Access-Token": accessToken,
		"X-Team-Id": teamId,
	};

	const url = version.id
		? HOST + VERSIONS_URL.replace(":application_id", app_id) + "/" + version.id
		: HOST + VERSIONS_URL.replace(":application_id", app_id);

	return axios
		.request({
			method: version.id ? "PUT" : "POST",
			headers,
			url,
			data: version,
		})
		.then((response) => {
			if (response && response.data) {
				return response.data as Version;
			}
			throw new Error("Cannot update version");
		});
};

const requestVersion = (
	application_id: string,
	version: Version,
	type: "submit" | "cancel_submit" | "archive"
) =>
	request<Version>(
		"POST",
		VERSIONS_URL.replace(":application_id", application_id as string) +
			"/" +
			version.id +
			"/" +
			type,
		{}
	);

export const submitForReview = (application_id: string, version: Version) =>
	requestVersion(application_id, version, "submit");

export const cancelSubmitForReview = (
	application_id: string,
	version: Version
) => requestVersion(application_id, version, "cancel_submit");

export const archiveVersion = (application_id: string, version: Version) =>
	requestVersion(application_id, version, "archive");
