import React, { useEffect } from "react";
import { Menu, Badge } from "antd";
import {
	PlusCircleOutlined,
	ProfileOutlined,
	SettingOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { UpdateIdentifier } from "app-redux/actions";

type Props = {
	unread: number;
};

export default function UserHeader({ unread }: Props) {
	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<Menu
			onClick={(e) => {
				const { key } = e;
				if (key == "logout") {
					dispatch(UpdateIdentifier(null));
					history.replace("/");
				} else {
					history.push("/personal/" + key);
				}
			}}
		>
			<Menu.Item key="info">
				<span>
					<PlusCircleOutlined /> Thông tin tài khoản
				</span>
			</Menu.Item>
			<Menu.Item key="inbox">
				<span>
					<ProfileOutlined /> Hộp thư <Badge count={unread} />
				</span>
			</Menu.Item>
			<Menu.Item key="setting">
				<span>
					<SettingOutlined /> Cài đặt
				</span>
			</Menu.Item>
			<Menu.Item key="logout">
				<span>
					<LogoutOutlined /> Đăng xuất
				</span>
			</Menu.Item>
		</Menu>
	);
}
