import React, { Component, useState, useEffect } from "react";
import moment from "moment";
import {
	Table,
	Input,
	Tag,
	Row,
	Col,
	Typography,
	Button,
	Modal,
	message,
	Space,
	Avatar,
} from "antd";
import { Dispatch } from "redux";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { HOST } from "config/api";
import { ApplicationState, User, Application, Version, Team } from "schema/app";
import { UpdateUser } from "app-redux/actions";
import { ApplicationApi, ReviewApi } from "api";
import { VersionStatus } from "uikit";
import { SendMessageModal, AppDetailsModal } from "../components";

const columns = (onSendMessage, onArchive, onShowAppDetails) => {
	return [
		{
			title: "Ứng dụng",
			dataIndex: "name",
			key: "name",
			sorter: {
				compare: (a: Application, b: Application) =>
					a.name.localeCompare(b.name),
				multiple: 1,
			},
			render: (value: boolean, record: Application, index: number) => {
				return (
					<a
						onClick={() => {
							onShowAppDetails(record);
						}}
					>
						<Space>
							<Avatar src={`${HOST}${record.logo}`} />
							<Typography.Text>{record.name}</Typography.Text>
						</Space>
					</a>
				);
			},
		},
		{
			width: 100,
			title: "Phiên bản mới nhất",
			key: "version",
			render: (app: Application) => {
				return <span>{app.version}</span>;
			},
		},
		{
			width: 120,
			title: "Trạng thái",
			key: "status",
			render: (app: Application) => {
				const stt = app.review_session?.status || app.review_version?.status;
				if (stt) {
					return <VersionStatus status={stt} />;
				} else {
					return <span>Chưa có phiên bản</span>;
				}
			},
		},
		{
			width: 200,
			title: "Cập nhật lần cuối",
			dataIndex: "updated_at",
			key: "updated_at",
			render: (updated_at: number) => {
				return <span>{moment(updated_at).fromNow()}</span>;
			},
			sorter: {
				compare: (a: Application, b: Application) =>
					b.updated_at - a.updated_at,
				multiple: 1,
			},
		},
		{
			title: "Author",
			dataIndex: "team",
			key: "team",
			render: (team: Team) => {
				return <span>{team?.name}</span>;
			},
		},
		{
			title: "",
			dataIndex: "action",
			key: "action",
			render: (value: boolean, application: Application, index: number) => {
				return (
					<Space size={10}>
						<Button
							type="primary"
							onClick={() => {
								onSendMessage(application, index);
							}}
						>
							Gửi cảnh báo
						</Button>
						<Button
							disabled={
								application.review_version.status === "archived" ||
								application.review_version.status === "rejected"
							}
							type="primary"
							danger
							onClick={() => {
								onArchive(application, index);
							}}
						>
							Gỡ
						</Button>
					</Space>
				);
			},
		},
	];
};

export default function AppSearch() {
	const identifier = useSelector((state: ApplicationState) => state.identifier);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [apps, setApps] = useState<Array<Application>>([]);
	const [currentAppId, setCurrentAppId] = useState("");
	const [currentAppTeamId, setCurrentAppTeamId] = useState("");
	const [showAppDetailsModal, setShowAppDetailsModal] = useState(false);
	const [selectedApp, setSelectedApp] = useState({});

	let timer: number = undefined;

	const loadApplications = (keyword: string = undefined) => {
		setLoading(true);
		ApplicationApi.listForSearch(keyword)
			.then((applications) => {
				setLoading(false);
				setApps(applications);
			})
			.catch((err) => {
				setLoading(false);
				setApps([]);
			});
	};

	useEffect(() => {
		loadApplications();
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [identifier.team_id]);

	const search = (keyword: string) => {
		if (keyword.length > 3 || keyword.length == 0) {
			if (timer) clearTimeout(timer);
			timer = window.setTimeout(() => {
				loadApplications(keyword);
			}, 500);
		}
	};

	const onSendMessage = (application: Application, index: number) => {
		setShowCreateModal(true);
		setCurrentAppId(application.id);
		setCurrentAppTeamId(application.team.id);
	};

	const onArchive = (application: Application, index: number) => {
		Modal.confirm({
			title: "Bạn có chắc muốn gỡ ứng dụng này khỏi cửa hàng không?",
			icon: <ExclamationCircleOutlined />,
			okText: "Có",
			okType: "danger",
			cancelText: "Không",
			onOk() {
				ApplicationApi.archive(application.id, application.review_version.id)
					.then((app: Application) => {
						if (app.id) {
							const index = apps.findIndex((item: Application) => {
								return item.id === app.id;
							});
							if (index !== -1) {
								let newApps = apps;
								newApps[index] = app;
								setApps(newApps.slice());
							}
						} else {
							message.error("Lỗi không thể gỡ ứng dụng này!");
						}
					})
					.catch(() => {});
			},
			onCancel() {},
		});
	};

	const onShowAppDetails = (app: Application) => {
		setSelectedApp(app);
		setShowAppDetailsModal(true);
	};

	return (
		<Row>
			<Col span={24}>
				<Typography.Title level={3}>Quản lý ứng dụng</Typography.Title>
				<Input.Search
					placeholder="Tìm kiếm ứng dụng"
					onChange={(e) => search(e.target.value)}
					style={{ width: "100%" }}
				/>
				<Table
					loading={loading}
					style={{ marginTop: 20 }}
					dataSource={apps}
					columns={columns(onSendMessage, onArchive, onShowAppDetails)}
					rowKey={(record) => record.id}
				></Table>
			</Col>
			<SendMessageModal
				visible={showCreateModal}
				team_id={currentAppTeamId}
				app_id={currentAppId}
				onOk={() => {
					setShowCreateModal(false);
				}}
				onCancel={() => {
					setShowCreateModal(false);
				}}
			/>
			<AppDetailsModal
				visible={showAppDetailsModal}
				application={selectedApp}
				onOk={() => {
					setShowAppDetailsModal(false);
				}}
				onCancel={() => {
					setShowAppDetailsModal(false);
				}}
			/>
		</Row>
	);
}
